import React from "react";
import "./Menu.css";
import { useNavigate } from "react-router-dom";

const Menu = ({ showmenu, menuref }) => {
  const navigate = useNavigate();
  const toprofile = () => {
    const d = JSON.parse(localStorage.getItem("data"));
    const type = localStorage.getItem("usertype");
    const screen = localStorage.getItem("screen");
    if (type === "1") {
      if (screen === "1") {
        navigate("/ClientDetails");
      } else if (screen === "2") {
        navigate("/Billing&carddetail");
      } else {
        navigate("/EditProfile");
      }
    } else if (type === "2") {
      if (screen === "1") {
        navigate("/BecameaCompanion");
      } else if (screen === "2") {
        navigate("/BecameaCompanion2");
      } else if (screen === "3") {
        navigate("/BecameaCompanion3");
      } else if (screen === "4") {
        navigate("/Daily_Availability");
      } else {
        navigate("/EditProfile");
      }
    } else {
      navigate("/Login");
    }
    showmenu(false);
  };
  const logout = () => {
    localStorage.clear();
    showmenu(false);
    navigate("/Login");
  };

  return (
    <>
      <div className="menumodal_content" ref={menuref}>
        <div className="menu_drop" />
        <div className="menu_detail" onClick={toprofile}>
          <svg
            width="15"
            height="18"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M9.58984 11C12.3513 11 14.5898 8.76142 14.5898 6C14.5898 3.23858 12.3513 1 9.58984 1C6.82842 1 4.58984 3.23858 4.58984 6C4.58984 8.76142 6.82842 11 9.58984 11Z"
                stroke="var(--black)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.18 21C18.18 17.13 14.33 14 9.59 14C4.85 14 1 17.13 1 21"
                stroke="var(--black)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <p>View Profile</p>
        </div>
        <div className="menu_detail" onClick={logout}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M11 1C16.52 1 21 5.48 21 11C21 16.52 16.52 21 11 21C5.48 21 1 16.52 1 11"
                stroke="var(--black)"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 9.9998L1.8 1.7998"
                stroke="var(--black)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.00008 5.83V1H5.83008"
                stroke="var(--black)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <p>LogOut</p>
        </div>
      </div>
    </>
  );
};
export default Menu;
