import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { stripeurl } from "../../service/Mainservice";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: { xs: "60%", sm: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "402px" },
  bgcolor: "var(--white)",
  p: 1,
  borderRadius: "4px",
  border: "none",
  height: "170px",
  textAlign: "center",
};

const KYCPopup = ({ isOpen, setisOpen, url }) => {
  const Navigate = useNavigate();

  const handleCompleteKYC = async () => {
    try {
      window.location.assign(url);
      setisOpen(false);
    } catch (error) {
      console.log(error);
      // seterror(error.message);
      // setOpen(true);
    }
  };

  const onClose = () => {
    setisOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setisOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgb(0, 0, 0, 0.55)",
      }}
    >
      <Box sx={style}>
        <h2 className="kyctitle">Complete KYC for Payment</h2>
        <p className="kycdes">
          To receive payments, please complete your KYC process.Without this you
          can not getting booking
        </p>
        <div className="kycbuttons">
          <button onClick={handleCompleteKYC}>Complete KYC Now</button>
          {/* <button onClick={onClose}>Remind Me Later</button> */}
        </div>
      </Box>
    </Modal>
  );
};

export default KYCPopup;
