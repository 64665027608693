import renderHTML from "react-render-html";

import { useEffect, useState } from "react";
import { terms } from "../service/Authservice";
import Header from "../Layout/Header";

const Terms = () => {
  const [data, setdata] = useState();

  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    try {
      const response = await terms();
      const td = renderHTML(response.data.data);

      setdata(td);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header title="Terms of Service" headerbg="addressbg" />

      <p className="terms-policy">{data} </p>
    </>
  );
};
export default Terms;
