import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import "./Needhelp.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: "580px" },
  bgcolor: "var(--white)",
  borderRadius: { xs: "0", md: "10px" },
  border: "none",
  outline: "none",

  padding: "10",
  height: { xs: "100%", md: "400px" },
};

const validate = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = "Required";
  } else if (values.title.length > 100) {
    errors.title = "Must be 100 characters or less";
  }
  if (!values.message) {
    errors.message = "Required";
  } else if (values.message.length > 200) {
    errors.message = "Must be 100 characters or less";
  }

  return errors;
};

const Needhelp = ({ need, setneed, handleneedhelp }) => {
  // const handleClose = () => {
  //   setShowlogin(false);
  // };

  const handlesubmit = async (values, { resetForm }) => {
    handleneedhelp(values, { resetForm });
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      message: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
      console.log(values);
    },
  });

  return (
    <>
      <Modal
        open={need}
        onClose={() => setneed(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "rgb(0, 0, 0, 0.75)",
        }}
      >
        <Box sx={style}>
          <h1 className="auth_title">Need Help?</h1>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="Needhelp_input">
              <label htmlFor="title">Title</label>
              <br />
              <input
                onChange={formik.handleChange}
                value={formik.values.title}
                name="title"
                type="text"
                placeholder="Title"
              />
            </div>
            {formik.errors.title ? (
              <div className="validation_error">{formik.errors.title}</div>
            ) : null}
            <div className="Needhelp_input">
              <label htmlFor="message">Message</label>
              <br />
              <textarea
                onChange={formik.handleChange}
                value={formik.values.message}
                cols={15}
                rows={5}
                name="message"
                placeholder="Write Here"
              />
              {formik.errors.message ? (
                <div className="validation_error" style={{ margin: "2px 0" }}>
                  {formik.errors.message}
                </div>
              ) : null}
            </div>

            <button type="submit" className="auth_submitbutton">
              Submit
            </button>
          </form>
        </Box>
      </Modal>
    </>
  );
};
export default Needhelp;
