import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import caregiverimg from "../../assets/caregiverimg.jpg";
import clientijmg from "../../assets/clientimg.jpg";

const Choosetyp = () => {
  const [typename, settypename] = useState("");

  const navigate = useNavigate();
  const unshowref = useRef();

  useEffect(() => {
    settypename("");
    const handleClickOutside = (event) => {
      if (unshowref.current && !unshowref.current.contains(event.target)) {
        navigate("/");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="Auth_BG">
        <div className="Type_main" ref={unshowref}>
          <h1 className="auth_title">Sign Up as</h1>

          <div className="choosemodal">
            <div className="choosemodal_div">
              <p>Client</p>
              <img
                src={clientijmg}
                alt=""
                className={
                  typename === "1" ? "Type_img selected_type" : "Type_img "
                }
                onClick={() =>
                  navigate("/SignUpemail", {
                    state: {
                      type: "1",
                    },
                  })
                }
              />
            </div>
            <div className="choosemodal_div">
              <p>Companion</p>
              <img
                src={caregiverimg}
                alt=""
                className={
                  typename === "2" ? "Type_img selected_type" : "Type_img "
                }
                onClick={() =>
                  navigate("/SignUpemail", {
                    state: {
                      type: "2",
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Choosetyp;
