import axios from "axios";
import generateKey from "../key";

const headers = () => {
  return {
    headers: {
      "Access-key": generateKey(),
    },
  };
};

const login = async (data, token) => {
  const params = {
    email: data.email,
    password: data.password,
    device_type: "Website",
    device_token: token,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/login`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const signupemail = async (data) => {
  const params = new FormData();
  params.append("email", data);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/VerifyEmail`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const emailotpverify = async (data, token) => {
  const otpno = Number(data);
  const params = {
    otp: otpno,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/EmailOTPVerificaton`,
      params,
      {
        headers: {
          Authentication: token,
          "Access-key": generateKey(),
        },
      }
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const register = async (data, country, number, type, token, email) => {
  const params = {
    email: email,
    password: data.password,
    device_type: "Website",
    device_token: token,
    country_code: country,
    phone_number: number,
    full_name: data.name,
    user_type: type,
    referral_source: data.source,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/Register`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const forgetpassword = async (data) => {
  const params = new FormData();
  params.append("email", data);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/ForgotOtp`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const otpverify = async (data, token) => {
  const otpno = Number(data);
  const params = {
    otp: otpno,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/OtpVerification`,
      params,
      {
        headers: {
          Authentication: token,
          "Access-key": generateKey(),
        },
      }
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

const resetpassword = async (data, token) => {
  const params = new FormData();
  params.append("new_password", data);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/NewPassword`,
      params,
      {
        headers: {
          Authentication: token,
          "Access-key": generateKey(),
        },
      }
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const terms = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}common/TermsAndCondition`,
      {
        headers: {
          "Access-key": generateKey(),
        },
      }
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const privacy = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}common/PrivacyPolicy`,
      {
        headers: {
          "Access-key": generateKey(),
        },
      }
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
export default login;
export {
  signupemail,
  emailotpverify,
  register,
  forgetpassword,
  otpverify,
  resetpassword,
  terms,
  privacy,
};
