import "./Loader.css";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => (
  <div className="loaderdiv">
    <CircularProgress sx={{ color: "var(--pink)" }} />{" "}
  </div>
);

export default Loader;
