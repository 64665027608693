import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import renderHTML from "react-render-html";

import "./Terms.css";
import { useEffect, useState } from "react";
import { privacy, terms } from "../../service/Authservice";
const style = {
  position: "absolute",
  top: { xs: "60%", sm: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "482px" },
  bgcolor: "var(--white)",
  p: 1,
  borderRadius: "10px",
  border: "none",
  height: "560px",
  textAlign: "center",
};

const Privacy = ({ showTerms, setShowTerms }) => {
  const [data, setdata] = useState();

  const handleClose = () => {
    setShowTerms(false);
  };
  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    try {
      const response = await privacy();
      const td = renderHTML(response.data.data);

      setdata(td);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        open={showTerms}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "rgb(0, 0, 0, 0.75)",
        }}
      >
        <Box sx={style}>
          <div className="Terms_titlediv">
            <div />
            <h1 className="Terms_title">Privacy Policy</h1>
            <CloseIcon
              className="closepopup"
              onClick={() => setShowTerms(false)}
            />
          </div>
          <p className="Terms_data">{data} </p>
          <button
            className="Bookcaregiver_button"
            onClick={() => setShowTerms(false)}
          >
            I Agree
          </button>
        </Box>
      </Modal>
    </>
  );
};
export default Privacy;
