import { useNavigate } from "react-router-dom";
import caregiverimg from "../assets/howbg.jpg";
import Header from "../Layout/Header";
import { useState } from "react";
import { useFormik } from "formik";
import { contact } from "../service/Mainservice";
import { Alert, Snackbar } from "@mui/material";
import Loader from "../Loader/Loader";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 100) {
    errors.name = "Must be 100 characters or less";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.message) {
    errors.message = "Required";
  } else if (values.message.length > 200) {
    errors.message = "Must be 100 characters or less";
  }
  return errors;
};

const How = () => {
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setsuccess] = useState();

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handlesubmit = async (values, { resetForm }) => {
    setload(true);

    try {
      const response = await contact(values);
      setsuccess(response.data.message);
      setOpen(true);

      resetForm();
      setload(false);
    } catch (error) {
      console.log(error);

      setload(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });

  return (
    <>
      <Header title="How it Works" headerbg="howbg" />
      <div className="Bookcaregiverdiv">
        <img
          src={caregiverimg}
          alt=""
          className="Bookcaregiverimg"
          style={{ margin: "30px 0" }}
        />

        <div className="how_left">
          <h1 className="howtitle">How it Works</h1>
          <p className="howdes">
            Currently serving the Bay Area, CA, i-Companion Care provides
            in-person assistance, support, and companionship care.
          </p>
          <div className="how_list">
            <p className="how_num">1</p>
            <p className="how_num_info">
              Enroll easily on our website as either a "companion" or a "client"
              seeking care. Our enrollment process is simple.
            </p>
          </div>
          <div className="how_list">
            <p className="how_num">2</p>
            <p className="how_num_info">
              Arrange a service with a companion. Once you're on the platform,
              you can schedule appointments conveniently using our website
              accessible via iPhone and Android as a web app.{" "}
            </p>
          </div>
          <div className="how_list">
            <p className="how_num">3</p>
            <p className="how_num_info">
              Provide feedback by rating each other (caregiver and client) and
              let us know about your experience. Your ratings matter to maintain
              good standing on our platform.{" "}
            </p>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      {load && <Loader />}

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
    </>
  );
};
export default How;
