import { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import caregiverimg from "../../assets/caregiverleft2.png";
import Terms from "./Terms";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  useStripe,
  useElements,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { completeprofile2 } from "../../service/Mainservice";
import Map from "./Map";
import { Alert, Snackbar } from "@mui/material";
import Privacy from "./Privacy";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const validate = (values) => {
  const errors = {};

  if (!values.zipcode) {
    errors.zipcode = "Required";
  }
  if (!values.city) {
    errors.city = "Required";
  }

  return errors;
};

const Bookcaregiver2 = () => {
  const [load, setload] = useState(true);
  const [term, setTerm] = useState(false);
  const [privacy, setprivacy] = useState(false);
  const [city, setcity] = useState();
  const [code, setcode] = useState();
  const [markerPlace, setmarkerPlace] = useState();
  const [placeerror, setplaceerror] = useState();
  const [cerror, setcerror] = useState();
  const [open, setOpen] = useState(false);
  const [error, seterror] = useState();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    formik.setFieldValue("zipcode", code);
    formik.setFieldValue("city", city);
  }, [markerPlace]);

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "15px",
        ":-webkit-autofill": {},
        "::placeholder": {},
      },
      invalid: {
        iconColor: "#FFC7EE",
        color: "#FFC7EE",
      },
    },
  };

  const handlesubmit = async (values, { resetForm }) => {
    if (markerPlace === undefined) {
      setplaceerror("Please select address");
    } else if (placeerror) {
      setplaceerror("You need to select valid place");
    } else {
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement) {
        setcerror("Please Enter All card details");
      } else {
        const { token, error } = await stripe.createToken(cardNumberElement);
        if (error) {
          seterror(error.message);
          setOpen(true);
        } else {
          const { lat, lng } = markerPlace.geometry.location;
          try {
            const data = {
              values,
              cardtoken: token.id,
              billinglat: lat(),
              billinglong: lng(),
              billingaddress: markerPlace.formatted_address,
            };

            const response = await completeprofile2(data);
            localStorage.setItem("screen", "3");
            navigate("/EditProfile");
            setload(false);
            resetForm();
          } catch (error) {
            console.log(error);
            setload(false);
          }
        }
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      zipcode: "",
      city: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });
  const handlecheck = (e) => {
    const { checked } = e.target;
    if (checked) {
      setTerm(true);
    } else {
      setTerm(false);
    }
  };
  const handlecheck2 = (e) => {
    const { checked } = e.target;
    if (checked) {
      setprivacy(true);
    } else {
      setprivacy(false);
    }
  };
  return (
    <>
      <Header title="Complete Profile" headerbg="caregiverbg2" />
      <div className="Bookcaregiverdiv">
        <div className="Bookcaregiverdiv_left">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <h1>Billing Details</h1>
            <h2>Billing Address</h2>
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="address1">Address</label>
              <Map
                setMarkerPlace={setmarkerPlace}
                setplaceerror={setplaceerror}
                setcity={setcity}
                setcode={setcode}
              />

              {placeerror ? (
                <div className="validation_error" style={{ margin: "2px 0" }}>
                  {placeerror}
                </div>
              ) : null}
            </div>
            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="zipcode">Zip code</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.zipcode}
                  className="Bookcaregiver_input"
                  name="zipcode"
                  type="text"
                  placeholder="Zip code"
                />
                {formik.errors.zipcode ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.zipcode}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="city">City</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  className="Bookcaregiver_input"
                  name="city"
                  type="text"
                  placeholder="City"
                />
                {formik.errors.city ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.city}
                  </div>
                ) : null}
              </div>
            </div>
            {/* {load ? (
              <>
                <h2>Select card</h2>

                <CircularProgress sx={{ color: "var(--black)" }} />
              </>
            ) : cardlist ? (
              <>
                <h2>Select card</h2>
                <div className="payment_allcards">
                  {cardlist?.map((card, index) => (
                    <div className="payment_cardinfo" style={{ width: "100%" }}>
                      <img src={card.brand} alt="" />
                      XXXX XXXX XXXX {card.last4}
                      <div className="payment_cardselected">
                        {card.is_selected === 1 ? (
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.99935 17.3334C4.39685 17.3334 0.666016 13.6025 0.666016 9.00002C0.666016 4.39752 4.39685 0.666687 8.99935 0.666687C13.6018 0.666687 17.3327 4.39752 17.3327 9.00002C17.3327 13.6025 13.6018 17.3334 8.99935 17.3334ZM8.16852 12.3334L14.0602 6.44085L12.8818 5.26252L8.16852 9.97669L5.81102 7.61919L4.63268 8.79752L8.16852 12.3334Z"
                              fill="#27BD23"
                            />
                          </svg>
                        ) : (
                          <div
                            className="payment_unselected"
                            onClick={() => handleselectcard(card.id)}
                          />
                        )}
                      </div>
                    </div>
                  ))}{" "}
                </div>
              </>
            ) : (
              <>
                {" "} */}
            <h2>Card Details</h2>
            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Name on Card</label>
                <input
                  required
                  name="name"
                  type="text"
                  placeholder="Name on Card"
                  className="Bookcaregiver_input"
                />
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Card Number</label>
                <div className="cardelementinput">
                  <CardNumberElement options={cardElementOptions} />
                </div>
              </div>
            </div>
            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Expiry Date</label>
                <div className="cardelementinput">
                  <CardExpiryElement options={cardElementOptions} />
                </div>
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">CVV</label>
                <div className="cardelementinput">
                  <CardCvcElement options={cardElementOptions} />
                </div>
              </div>
            </div>
            {cerror ? (
              <div className="validation_error" style={{ margin: "2px 0" }}>
                {cerror}
              </div>
            ) : null}

            <div className="Bookcaregiver_check" onClick={() => setTerm(true)}>
              <input
                type="checkbox"
                name="agree"
                id="agree"
                onChange={handlecheck}
                required
              />
              <label htmlFor="agree">
                {" "}
                I agree to the <span>Terms of Service</span>
              </label>
            </div>
            <div
              className="Bookcaregiver_check"
              onClick={() => setprivacy(true)}
            >
              <input
                type="checkbox"
                name="agree2"
                id="agree2"
                onChange={handlecheck2}
                required
              />
              <label htmlFor="agree">
                {" "}
                I agree to the <span>Privacy Policy</span>
              </label>
            </div>
            <div className="Bookcaregiver_btngrp">
              <button
                className="Bookcaregiver_prvbutton"
                onClick={() => navigate("/BookaCompanion")}
              >
                Prev
              </button>
              <button className="Bookcaregiver_button" type="submit">
                Next
              </button>
            </div>
          </form>
        </div>
        <img src={caregiverimg} alt="" className="Bookcaregiverimg" />
        <Terms showTerms={term} setShowTerms={setTerm} />
        <Privacy showTerms={privacy} setShowTerms={setprivacy} />
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={closealert}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};
export default function stripe() {
  return (
    <Elements stripe={stripePromise}>
      <Bookcaregiver2 />
    </Elements>
  );
}
