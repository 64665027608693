import { Link, useLocation } from "react-router-dom";
import Header from "../../Layout/Header";
import Profilemenu from "./Profilemenu";
import { useEffect, useState } from "react";
import Rate from "./Ratemodal";
import { completebooking } from "../../service/Mainservice";
import Loader from "../../Loader/Loader";
import Pagination from "../../Pagination/Pagination";

const Completedbook = () => {
  const [list, setlist] = useState();
  const [load, setload] = useState(true);

  const [rate, setrate] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState();
  const [selectedbookid, setselectedbookid] = useState(false);

  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    getcomplete(1);
  }, []);
  const getcomplete = async (val) => {
    try {
      const response = await completebooking(val);
      setlist(response.data.data);
      setcurrentPage(response.data.pagination.current_pages);
      settotalPages(response.data.pagination.total_pages);

      setload(false);
    } catch (error) {
      setload(false);

      console.log(error);
    }
  };

  return (
    <>
      <Header title="My Booking" headerbg="completebg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">My Booking</h2>
          <div className="booking_status">
            <Link
              to="/MybookingUpcoming"
              className={
                path === "/MybookingUpcoming"
                  ? "booking_status_title selected_title"
                  : "booking_status_title"
              }
            >
              Upcoming
            </Link>
            <Link
              to="/MybookingCompleted"
              className={
                path === "/MybookingCompleted"
                  ? "booking_status_title selected_title"
                  : "booking_status_title"
              }
            >
              Completed
            </Link>
          </div>
          {list ? (
            <div>
              <div className="mybooking_cardlist">
                {list.map((data) => (
                  <div className="mybooking_card">
                    <div className="mybooking_cardup">
                      <div className="mybooking_cardinfo">
                        <p>Request ID</p>
                        <h3>{data.request_id}</h3>
                      </div>
                      <div className="mybooking_cardinfo">
                        <p>Booking Date</p>
                        <h3>{data.booking_date}</h3>
                      </div>
                      <div className="mybooking_cardinfo">
                        <p>Request Date</p>
                        <h3>{data.request_date}</h3>
                      </div>
                    </div>
                    <div className="mybooking_cardup">
                      <div className="mybooking_cardinfo">
                        <p>Time</p>
                        <h3>{data.booking_time}</h3>
                      </div>
                      <div className="mybooking_cardinfo">
                        <p>Address</p>
                        <h3>{data.address}</h3>
                      </div>
                    </div>
                    <div className="mybooking_cardup">
                      <div className="mybooking_cardinfo">
                        <p>Companion Assigned</p>
                        <h3 className="mybooking_caretakerdata">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}${data.caregiver_pic}`}
                          />
                          {data.caregiver_name}
                        </h3>
                      </div>
                    </div>
                    {data.rate === null ? (
                      <p
                        className="mybooking_help"
                        style={{ cursor: "pointer" }}
                        onClick={() => (setrate(true), setselectedbookid(data))}
                      >
                        Rate Now
                      </p>
                    ) : (
                      <p className="mybooking_help">
                        Rated{"  "}
                        <span>
                          {" "}
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_29_684)">
                              <path
                                d="M7.00063 9.91665L3.57179 12.0108L4.50396 8.10248L1.45312 5.48915L5.45771 5.16831L7.00063 1.45831L8.54354 5.16831L12.5487 5.48915L9.49729 8.10248L10.4295 12.0108L7.00063 9.91665Z"
                                fill="#F2B006"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_29_684">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {data.rate}
                        </span>
                      </p>
                    )}
                  </div>
                ))}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                getorderlist={getcomplete}
              />
            </div>
          ) : (
            <div className="blank_booking">No Complete Bookings</div>
          )}
        </div>
        <Rate
          showRate={rate}
          setShowRate={setrate}
          id={selectedbookid}
          getcomplete={getcomplete}
        />
      </div>
      {load && <Loader />}
    </>
  );
};
export default Completedbook;
