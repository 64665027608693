import Router from "./Routes/index";

const App = () => {
  return (
    <>
      <Router />
    </>
  );
};

export default App;
