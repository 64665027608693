import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import AdsClickIcon from "@mui/icons-material/AdsClick";
const Profilemenu = () => {
  const [type, settype] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname;

  const user = localStorage.getItem("usertype");

  useEffect(() => {
    user === "1" ? settype(true) : settype(false);
  }, []);

  const handlelogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <div className="profilemenu">
        {type ? (
          <li
            className={
              path === "/BookaCompanion"
                ? "profilemenu_li selected_profilemenu"
                : "profilemenu_li"
            }
            onClick={() => navigate("/Booking_Type")}
          >
            <AdsClickIcon sx={{ color: "#312920" }} />
            Book a Companion
          </li>
        ) : (
          ""
        )}

        {type ? (
          <>
            <li
              className={
                path === "/MybookingUpcoming" || path === "/MybookingCompleted"
                  ? "profilemenu_li selected_profilemenu"
                  : "profilemenu_li"
              }
              onClick={() => navigate("/MybookingUpcoming")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_44_1062)">
                  <path
                    d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11Z"
                    fill="#312920"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_44_1062">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              My Booking
            </li>

            <li
              className={
                path === "/MyBilling"
                  ? "profilemenu_li selected_profilemenu"
                  : "profilemenu_li"
              }
              onClick={() => navigate("/MyBilling")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_45_391)">
                  <path
                    d="M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM8 7H16V9H8V7ZM8 11H16V13H8V11ZM8 15H13V17H8V15Z"
                    fill="#312920"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_45_391">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Billing Invoice
            </li>
            <li
              className={
                path === "/MyAddress"
                  ? "profilemenu_li selected_profilemenu"
                  : "profilemenu_li"
              }
              onClick={() => navigate("/MyAddress")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_45_391)">
                  <path
                    d="M20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM19 20V4H5V20H19ZM8 7H16V9H8V7ZM8 11H16V13H8V11ZM8 15H13V17H8V15Z"
                    fill="#312920"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_45_391">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Billing Address
            </li>
            <li
              className={
                path === "/PaymentDetails"
                  ? "profilemenu_li selected_profilemenu"
                  : "profilemenu_li"
              }
              onClick={() => navigate("/PaymentDetails")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_44_1074)">
                  <path
                    d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM14 15H18V17H14V15Z"
                    fill="#312920"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_44_1074">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Payment Details
            </li>
          </>
        ) : (
          <>
            <li
              className={
                path === "/CompanionUpcomingbook" ||
                path === "/CompanionCompletedbook"
                  ? "profilemenu_li selected_profilemenu"
                  : "profilemenu_li"
              }
              onClick={() => navigate("/CompanionUpcomingbook")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_44_1062)">
                  <path
                    d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11Z"
                    fill="#312920"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_44_1062">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              My Appointments
            </li>
            <li
              className={
                path === "/EarningorStubs"
                  ? "profilemenu_li selected_profilemenu"
                  : "profilemenu_li"
              }
              onClick={() => navigate("/EarningorStubs")}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM6.5 12H12C12.1326 12 12.2598 11.9473 12.3536 11.8536C12.4473 11.7598 12.5 11.6326 12.5 11.5C12.5 11.3674 12.4473 11.2402 12.3536 11.1464C12.2598 11.0527 12.1326 11 12 11H8C7.33696 11 6.70107 10.7366 6.23223 10.2678C5.76339 9.79893 5.5 9.16304 5.5 8.5C5.5 7.83696 5.76339 7.20107 6.23223 6.73223C6.70107 6.26339 7.33696 6 8 6H9V4H11V6H13.5V8H8C7.86739 8 7.74021 8.05268 7.64645 8.14645C7.55268 8.24021 7.5 8.36739 7.5 8.5C7.5 8.63261 7.55268 8.75979 7.64645 8.85355C7.74021 8.94732 7.86739 9 8 9H12C12.663 9 13.2989 9.26339 13.7678 9.73223C14.2366 10.2011 14.5 10.837 14.5 11.5C14.5 12.163 14.2366 12.7989 13.7678 13.2678C13.2989 13.7366 12.663 14 12 14H11V16H9V14H6.5V12Z"
                  fill="var(--black)"
                />
              </svg>
              Earnings/Stubs
            </li>

            <li
              className={
                path === "/Payments"
                  ? "profilemenu_li selected_profilemenu"
                  : "profilemenu_li"
              }
              onClick={() => navigate("/Payments")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_44_1074)">
                  <path
                    d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM14 15H18V17H14V15Z"
                    fill="#312920"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_44_1074">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Payments
            </li>
          </>
        )}
        {/* <li
          className={
            path === "/Helpcenter"
              ? "profilemenu_li selected_profilemenu"
              : "profilemenu_li"
          }
          onClick={() => navigate("/Helpcenter")}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_44_1078)">
              <path
                d="M12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12H7C7.53043 12 8.03914 12.2107 8.41421 12.5858C8.78929 12.9609 9 13.4696 9 14V19C9 19.5304 8.78929 20.0391 8.41421 20.4142C8.03914 20.7893 7.53043 21 7 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12V19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H17C16.4696 21 15.9609 20.7893 15.5858 20.4142C15.2107 20.0391 15 19.5304 15 19V14C15 13.4696 15.2107 12.9609 15.5858 12.5858C15.9609 12.2107 16.4696 12 17 12H20C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM4 14V19H7V14H4ZM17 14V19H20V14H17Z"
                fill="#312920"
              />
            </g>
            <defs>
              <clipPath id="clip0_44_1078">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Help Center
        </li> */}
        <li
          className={
            path === "/ReferaFriend"
              ? "profilemenu_li selected_profilemenu"
              : "profilemenu_li"
          }
          onClick={() => navigate("/ReferaFriend")}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM18 17V13.5L23 18L18 22.5V19H15V17H18Z"
              fill="#312920"
            />
          </svg>
          Refer a Friend
        </li>
        <li
          className={
            path === "/EditProfile"
              ? "profilemenu_li selected_profilemenu"
              : "profilemenu_li"
          }
          onClick={() => navigate("/EditProfile")}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_44_1058)">
              <path
                d="M6.414 16.0001L16.556 5.85808L15.142 4.44408L5 14.5861V16.0001H6.414ZM7.243 18.0001H3V13.7571L14.435 2.32208C14.6225 2.13461 14.8768 2.0293 15.142 2.0293C15.4072 2.0293 15.6615 2.13461 15.849 2.32208L18.678 5.15108C18.8655 5.33861 18.9708 5.59292 18.9708 5.85808C18.9708 6.12325 18.8655 6.37756 18.678 6.56508L7.243 18.0001ZM3 20.0001H21V22.0001H3V20.0001Z"
                fill="var(--black)"
              />
            </g>
            <defs>
              <clipPath id="clip0_44_1058">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Edit Profile
        </li>
        <li className="profilemenu_li" onClick={handlelogout}>
          <LogoutIcon sx={{ color: "#312920" }} /> Logout
        </li>
      </div>
    </>
  );
};
export default Profilemenu;
