import axios from "axios";
import generateKey from "../key";
const moment = require("moment");
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getToken = () => {
  return localStorage.getItem("ntk");
};

const headers = () => {
  const token = getToken();

  return {
    headers: {
      Authentication: token,
      "Access-key": generateKey(),
    },
  };
};

const profile = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}common/UpdateOrGetDetails`,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const editprofile = async (name, image) => {
  try {
    const params = new FormData();
    params.append("full_name", name);
    params.append("profile_pic", image);

    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}common/UpdateOrGetDetails`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const refer = async (value) => {
  try {
    const params = {
      full_name: value.name,
      email: value.email,
      // "country_code":"91",
      phone_number: value.contact,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/ReferFnd`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const contact = async (value) => {
  try {
    const params = {
      name: value.name,
      email: value.email,
      message: value.message,
      phone_number: String(value.contact),
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/ContactUs`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};

//----------------------------provider-----------------------------------

const becamecaregiver = async (data, screen) => {
  try {
    const bDate = moment(data.values.birthdate).format("DD/MM/YYYY");
    const params = new FormData();
    params.append("screen_no", screen);
    params.append("name", data.values.name);
    params.append("birth_date", bDate);
    params.append("address", data.address);
    params.append("lat", data.lat());
    params.append("long", data.long());
    params.append("driving_license", data.values.DL);
    params.append("driver_in_ca", data.isdrive);
    params.append("age", data.values.are18);
    params.append("ssn", data.values.SSN);

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}caregiver/CompleteProfile`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const becamecaregiver2 = async (data, screen) => {
  try {
    const bDate = moment(data.startdate).format("DD/MM/YYYY");
    const params = new FormData();
    params.append("screen_no", screen);
    params.append("start_date", bDate);
    params.append("consent", data.isconset);
    params.append("signature_text", data.upload);
    params.append("signature_date", moment(data.signdate).format("DD/MM/YYYY"));
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}caregiver/CompleteProfile`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const becamecaregiver3 = async (data, screen) => {
  try {
    const params = new FormData();
    params.append("screen_no", screen);
    params.append("dl_pic", data.dl);
    params.append("residence_or_passport_pic", data.passport);
    {
      data.certificate !== null &&
        params.append("cerification_pic", data.certificate);
    }
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}caregiver/CompleteProfile`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const becamecaregiver4 = async (data, screen) => {
  try {
    const params = new FormData();
    params.append("screen_no", screen);
    params.append("monday", data["MON"].fullday === "" ? 2 : 1);
    params.append("mon_s_time", data["MON"].start);
    params.append("mon_e_time", data["MON"].end);
    params.append("mon_day_selection", data["MON"].fullday);
    params.append("tuesday", data["TUE"].fullday === "" ? 2 : 1);
    params.append("tu_s_time", data["TUE"].start);
    params.append("tu_e_time", data["TUE"].end);
    params.append("tu_day_selection", data["TUE"].fullday);
    params.append("wednesday", data["WED"].fullday === "" ? 2 : 1);
    params.append("wed_s_time", data["WED"].start);
    params.append("wed_e_time", data["WED"].end);
    params.append("wed_day_selection", data["WED"].fullday);
    params.append("thursday", data["THU"].fullday === "" ? 2 : 1);
    params.append("th_s_time", data["THU"].start);
    params.append("th_e_time", data["THU"].end);
    params.append("th_day_selection", data["THU"].fullday);
    params.append("fridayday", data["FRI"].fullday === "" ? 2 : 1);
    params.append("fr_s_time", data["FRI"].start);
    params.append("fr_e_time", data["FRI"].end);
    params.append("fr_day_selection", data["FRI"].fullday);
    params.append("saturday", data["SAT"].fullday === "" ? 2 : 1);
    params.append("sa_s_time", data["SAT"].start);
    params.append("sa_e_time", data["SAT"].end);
    params.append("sa_day_selection", data["SAT"].fullday);
    params.append("sunday", data["SUN"].fullday === "" ? 2 : 1);
    params.append("su_s_time", data["SUN"].fullday);
    params.append("su_e_time", data["SUN"].fullday);
    params.append("su_day_selection", data["SUN"].fullday);

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}caregiver/CompleteProfile`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};

const stripeurl = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}caregiver/Payments`,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const costtotal = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}CareGiver/TotalEarning`,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const Withdraw = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}caregiver/WithdrawWalletfunds`,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const caregiverupcomingbooking = async (num) => {
  try {
    const params = {
      page: num,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}CareGiver/UpcomingBooking`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const caregivercompletebooking = async (num) => {
  try {
    const params = {
      page: num,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}CareGiver/CompletBooking`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const startjob = async (id, token) => {
  try {
    const params = {
      id: id,
      device_token: token,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}CareGiver/StartJob`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const endjob = async (id, token) => {
  try {
    const params = {
      id: id,
      device_token: token,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}CareGiver/CompleteJob`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const earningdate = async (d) => {
  try {
    const params = {
      date: moment(d).format("DD/MM/YYYY"),
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}CareGiver/EarningCalendar`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const earningbookingdetail = async (id) => {
  try {
    const params = {
      ids: id,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}CareGiver/BookingsbyIds`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};

// ---------------------client-------------------------------
const couponlist = async (type) => {
  try {
    const params = {
      timezone: timezone,
      booking_type: type, //Required   1 = One-time, 2 = recurring, 3 = flexible days
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/AvailableCoupons`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};

const serviceprice = async (select, hour, basic, id) => {
  try {
    const params = {
      service_category_id: select,
      service_package: basic ? "basic" : "premium", //basic
      booking_hours: hour,
      coupon_id: id,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}common/ServicePrice`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};

const completeprofile1 = async (data) => {
  try {
    const bdate = moment(data.birthdate).format("DD/MM/YYYY");
    const params = {
      screen_no: "2",
      name: data.name,
      birth_date: bdate,
      email: data.email,
      phone_number: data.contact,
      emergency_phone_number: data.econtact,
      emergency_contact_name: data.ename,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/CompleteProfile`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const completeprofile2 = async (data) => {
  try {
    const params = {
      screen_no: "3",
      billing_address: data.billingaddress,
      billing_lat: data.billinglat,
      billing_long: data.billinglong,
      zipcode: data.values.zipcode,
      city: data.values.city,
      card_token: data.cardtoken,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/CompleteProfile`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};

const bookcaregiver1 = async (
  data,
  time,
  hour,
  select,
  serviceIds,
  basic,
  cid
) => {
  const bdate = moment(data.values.birthdate).format("DD/MM/YYYY");
  const sdate = moment(data.values.startdate).format("DD/MM/YYYY");

  try {
    const params = {
      name: data.values.name,
      birth_date: bdate,
      address: data.address,
      lat: data.lat,
      long: data.long,
      booking_time: time,
      booking_hours: hour,
      s_booking_date: sdate,

      service_category_id: select,
      service_package: basic ? "basic" : "premium",
      service_type: serviceIds,
      booking_type: data.type,
      recurring_days: data.values.day,
      booking_timezone: timezone,
      ...(cid && { coupon_id: cid }),
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/BookCareGiver`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const bookcaregiver2 = async (
  data,
  time,
  hour,
  check,
  select,
  serviceIds,
  basic,
  cid
) => {
  const bdate = moment(data.values.birthdate).format("DD/MM/YYYY");
  console.log(cid);
  try {
    const sdate = moment(check[0]).format("DD/MM/YYYY");
    const edate = moment(check[1]).format("DD/MM/YYYY");
    const params = {
      name: data.values.name,
      birth_date: bdate,
      address: data.address,
      lat: data.lat,
      long: data.long,
      s_booking_date: sdate,
      e_booking_date: edate,
      booking_time: time,
      booking_hours: hour,
      booking_type: data.type,
      service_category_id: select,
      service_package: basic ? "basic" : "premium",
      service_type: serviceIds,
      booking_timezone: timezone,
      ...(cid && { coupon_id: cid }),
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/BookCareGiver`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const bookcaregiver = async (
  data,
  time,
  hour,
  selectedDates,
  select,
  serviceIds,
  basic,
  cid
) => {
  const bdate = moment(data.values.birthdate).format("DD/MM/YYYY");

  try {
    const params = {
      name: data.values.name,
      birth_date: bdate,
      address: data.address,
      lat: data.lat,
      long: data.long,
      booking_time: time,
      booking_hours: hour,
      service_category_id: select,
      service_package: basic ? "basic" : "premium",
      service_type: serviceIds,
      booking_type: data.type,
      flexible_dates: selectedDates,
      booking_timezone: timezone,
      ...(cid && { coupon_id: cid }),
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/BookCareGiver`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};

const servicetype = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}client/GetServicesInfo`,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const billinglist = async (num) => {
  try {
    const params = {
      page: num,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/InvoiceList`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const billingaddress = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}client/ClientBillingAddress`,
      headers()
    );
    return response.data;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const updatebillingaddress = async (data) => {
  try {
    const params = {
      billing_address: data.billingaddress,
      billing_lat: data.billinglat,
      billing_long: data.billinglong,
      zipcode: data.values.zipcode,
      city: data.values.city,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/ClientBillingAddress`,
      params,
      headers()
    );
    return response.data;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const generateinvoice = async (id) => {
  try {
    const params = {
      id: id,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/GenerateInvoice`,
      params,
      {
        headers: {
          Authentication: getToken(),
          "Access-key": generateKey(),
        },
        responseType: "blob",
      }
    );
    return response.data;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const upcomingbooking = async (num) => {
  try {
    const params = {
      page: num,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/UpcomingBookings`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const needhelp = async (value, id) => {
  try {
    const params = {
      title: value.title,
      msg: value.message,
      booking_id: id,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/NeedHelp`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }
    throw error.response;
  }
};
const completebooking = async (num) => {
  try {
    const params = {
      page: num,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/CompletBookings`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const canclebooking = async (values, id) => {
  try {
    const params = new FormData();
    params.append("booking_id", id);
    params.append("title", values.title);
    params.append("msg", values.message);
    params.append("image", values.doc);
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/CancelBooking`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};

const ratecaregiver = async (id, rate, review) => {
  try {
    const params = {
      booking_id: id,
      rate: rate,
      details: review,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/RateCareGiver`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const clientinfo = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}client/DefaultData`,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const savecard = async (params) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/SaveOrGetCard`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const getcard = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}client/SaveOrGetCard`,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const deletecard = async (id) => {
  try {
    const params = {
      card_id: id,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/DelCardApi`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};
const selectcard = async (id) => {
  try {
    const params = {
      card_id: id,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}client/SetCardAsDefault`,
      params,
      headers()
    );
    return response;
  } catch (error) {
    if (error.response.data.code === 401) {
      localStorage.clear();
    }

    throw error.response.data;
  }
};

export default profile;
export {
  completeprofile1,
  completeprofile2,
  becamecaregiver,
  becamecaregiver2,
  becamecaregiver3,
  becamecaregiver4,
  costtotal,
  Withdraw,
  stripeurl,
  caregiverupcomingbooking,
  caregivercompletebooking,
  bookcaregiver1,
  bookcaregiver2,
  bookcaregiver,
  serviceprice,
  couponlist,
  servicetype,
  clientinfo,
  billinglist,
  billingaddress,
  updatebillingaddress,
  generateinvoice,
  upcomingbooking,
  needhelp,
  completebooking,
  canclebooking,
  ratecaregiver,
  savecard,
  getcard,
  deletecard,
  selectcard,
  refer,
  contact,
  editprofile,
  startjob,
  endjob,
  earningdate,
  earningbookingdetail,
};
