// project import
// import Loadable from "../Loader/Loadable";
import Layout from "../Layout";
import Login from "../componant/Auth/Login";
import Home from "../componant/Home";
import Contact from "../componant/Contact";
import About from "../componant/About";
import Service from "../componant/Service";
import How from "../componant/Howwork";
import Terms from "../componant/Terms";
import Privacy from "../componant/Privacy";

// ==============================|| LANDING ROUTING ||============================== //

const LoginRoutes = {
  element: <Layout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/ContactUs",
      element: <Contact />,
    },
    {
      path: "/AboutUs",
      element: <About />,
    },
    {
      path: "/Service",
      element: <Service />,
    },
    {
      path: "/HowitWorks",
      element: <How />,
    },
    {
      path: "/Terms",
      element: <Terms />,
    },
    {
      path: "/Privacy",
      element: <Privacy />,
    },
  ],
};

export default LoginRoutes;
