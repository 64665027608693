import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import loginimg from "../../assets/Login.png";
import { Alert, Snackbar } from "@mui/material";
import "./Login.css";
import login from "../../service/Authservice";
import { stripeurl } from "../../service/Mainservice";
// import { messaging } from "../../Firebase";
// import { getToken } from "firebase/messaging";

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 6) {
    errors.password = "Must be at least 6 characters";
  } else if (values.password.length > 20) {
    errors.password = "Must be 20 characters or less";
  }

  return errors;
};

const Login = () => {
  const [error, seterror] = useState(false);
  const [open, setOpen] = useState(false);
  const [load, setload] = useState();
  const unshowref = useRef();

  const navigate = useNavigate();
  const [devicetoken, setdevicetoken] = useState();

  useEffect(() => {
    // const requestNotificationPermission = async () => {
    //   try {
    //     const permissionResult = await Notification.requestPermission();
    //     console.log(permissionResult);
    //     if (permissionResult === "granted") {
    //       const currentToken = await getToken(messaging, {
    //         vapidKey:
    //           "BOS2jENcfmydQnDnOwt6HvXqiYV4u8XQ0ay2_eYlLdj8B7THtDnKgYKKnvXR-LXRsEVsYqBOsvuLTnh10AE0rRc",
    //       });
    //       console.log(currentToken);
    //       setdevicetoken(currentToken);
    //       // Use the token as needed
    //     }
    //   } catch (error) {
    //     console.error("Error obtaining device token:", error);
    //   }
    // };

    // requestNotificationPermission();

    //hide login onclick outside of login container

    const handleClickOutside = (event) => {
      if (unshowref.current && !unshowref.current.contains(event.target)) {
        navigate("/");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handlesubmit = async (values, { resetForm }) => {
    setload(true);
    try {
      const response = await login(values, devicetoken);
      localStorage.setItem("ntk", response.data.token);
      localStorage.setItem("data", JSON.stringify(response.data.data));
      localStorage.setItem("usertype", response.data.data.user_type);
      localStorage.setItem("screen", response.data.data.screen_no);
      setload(false);
      resetForm();
      if (response.data.data.user_type === "1") {
        switch (response.data.data.screen_no) {
          case "1":
            navigate("/ClientDetails");
            break;
          case "2":
            navigate("/Billing&carddetail");
            break;
          case "3":
            navigate("/EditProfile");
            break;
        }
      } else {
        switch (response.data.data.screen_no) {
          case "1":
            navigate("/BecameaCompanion");
            break;
          case "2":
            navigate("/BecameaCompanion2");
            break;

          case "3":
            navigate("/BecameaCompanion3");
            break;

          case "4":
            navigate("/Daily_Availability");
            break;

          case "5":
            navigate("/EditProfile");

            break;
        }
      }
    } catch (error) {
      setload(false);
      seterror(error.message);
      setOpen(true);
    }
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });
  return (
    <>
      <div className="Auth_BG">
        <div className="Auth_main" ref={unshowref}>
          <img src={loginimg} alt="" className="Auth_img" />
          <div className="Auth_details">
            <h1 className="auth_title">Login</h1>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="auth_inputfield">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_21_537)">
                    <path
                      d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
                      fill="#312920"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_21_537">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  type="email"
                  placeholder="Email"
                />
              </div>
              {formik.errors.email ? (
                <div className="validation_error">{formik.errors.email}</div>
              ) : null}

              <div className="auth_inputfield">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5_432)">
                    <path
                      d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
                      fill="#312920"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5_432">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  // onChange={handlechange}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </div>
              {formik.errors.password ? (
                <div className="validation_error">{formik.errors.password}</div>
              ) : null}

              <p
                className="login_forgetpasswordtext"
                onClick={() => navigate("/ForgetPassword")}
              >
                Forgot Password?{" "}
              </p>
              <button
                type="submit"
                className="auth_submitbutton"
                disabled={load}
              >
                {load ? "Loading..." : "Login"}
              </button>
              <p className="auth_notaccount">
                Don’t have an account?{" "}
                <button
                  className="auth_secoundbutton"
                  onClick={() => navigate("/ChooseType")}
                >
                  Sign Up.{" "}
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Login;
