import Header from "../../Layout/Header";
import {
  earningbookingdetail,
  earningdate,
  stripeurl,
} from "../../service/Mainservice";
import Profilemenu from "./Profilemenu";
import { useEffect, useState } from "react";
import "./Earning.css";
import Loader from "../../Loader/Loader";
import KYCPopup from "./KYC";

const Earning = () => {
  const [data, setdata] = useState();
  const [selected, setselected] = useState(null);
  const [est, setest] = useState(0);
  const [booking, setbooking] = useState();
  const [load, setload] = useState(true);
  const [kyc, setkyc] = useState();
  const [url, seturl] = useState(false);

  useEffect(() => {
    getmasterdate(new Date());
    checkstripe();
  }, []);
  const checkstripe = async () => {
    const response = await stripeurl();
    console.log(response);
    if (response.data.url) {
      seturl(response.data.url);
      setkyc(true);
    }
  };

  const getmasterdate = async (e) => {
    try {
      const response = await earningdate(e);
      console.log(response);
      setdata(response.data);
      setload(false);
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };
  const handleselect = async (value) => {
    setselected(value.date);
    try {
      const response = await earningbookingdetail(value.ids);
      const total = response.data.data?.reduce(
        (acc, cur) => acc + parseFloat(cur.est_pay),
        0
      );
      setest(total);

      setbooking(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header title="Earning/Stubs" headerbg="earningbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">Earning/Stubs</h2>
          <div className="earningdaytitlediv">
            <p className="earningdaytitle">{data?.title_month}</p>
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => getmasterdate(data?.previous_monday)}
              >
                <circle
                  cx="20.5"
                  cy="20.5"
                  r="20"
                  fill="white"
                  stroke="#E3E3E3"
                />
                <g clip-path="url(#clip0_45_875)">
                  <path
                    d="M20.8282 20.9998L23.6572 18.1718L22.2432 16.7568L18.0002 20.9998L22.2432 25.2428L23.6572 23.8278L20.8282 20.9998Z"
                    fill="#312920"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_45_875">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="matrix(-1 0 0 1 33 9)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <svg
                width="32"
                height="32"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => getmasterdate(data?.next_monday)}
              >
                <circle
                  cx="20.5"
                  cy="20.5"
                  r="20"
                  fill="white"
                  stroke="#E3E3E3"
                />
                <g clip-path="url(#clip0_45_874)">
                  <path
                    d="M21.1718 20.9998L18.3428 18.1718L19.7568 16.7568L23.9998 20.9998L19.7568 25.2428L18.3428 23.8278L21.1718 20.9998Z"
                    fill="#312920"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_45_874">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(9 9)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          <div className="earningdays">
            <p className="earningdaysname">MON</p>
            <p className="earningdaysname">TUE</p>
            <p className="earningdaysname">WED</p>
            <p className="earningdaysname">THU</p>
            <p className="earningdaysname">FRI</p>
            <p className="earningdaysname">SAT</p>
            <p className="earningdaysname">SUN</p>
          </div>
          <div className="earningdays">
            {data?.respone_data?.map((date) => (
              <div className="earningdatedata">
                <p
                  className={
                    selected === date.date
                      ? "earningdate selectedearndate"
                      : "earningdate"
                  }
                  onClick={() => handleselect(date)}
                >
                  {date.date}
                </p>
                <p
                  className={
                    selected === date.date
                      ? "earningdayhour selectedearnhour"
                      : "earningdayhour"
                  }
                  onClick={() => handleselect(date)}
                >
                  {date.hours} Hours
                </p>
                <p className="earningdate">${date.earning}</p>
              </div>
            ))}
          </div>
          {selected !== null ? (
            booking ? (
              <>
                {booking.map((data) => (
                  <div className="erning_card">
                    <div className="mybooking_cardinfo">
                      <p>Caretaker</p>
                      <h3 className="mybooking_caretakerdata">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}${data.client_pic}`}
                        />
                        {data.client_name}
                      </h3>
                    </div>

                    <div className="mybooking_cardinfo1">
                      <p>Request ID</p>
                      <h3>{data.request_id}</h3>
                    </div>
                    <div className="mybooking_cardinfo1">
                      <p>Booking Date</p>
                      <h3>{data.booking_date}</h3>
                    </div>
                    <div className="mybooking_cardinfo1">
                      <p>Request Date</p>
                      <h3>{data.request_date}</h3>
                    </div>
                    <div className="mybooking_cardinfo1">
                      <p>Address</p>
                      <h3>{data.address}</h3>
                    </div>
                  </div>
                ))}
                {/* <h1>Estimated Cost:{est}</h1> */}
              </>
            ) : (
              <div className="blank_booking">No Upcoming Bookings</div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
      {load && <Loader />}
      <KYCPopup isOpen={kyc} setisOpen={setkyc} url={url} />
    </>
  );
};
export default Earning;
