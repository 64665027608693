import { ChangeEvent, useState } from "react";
import Header from "../../Layout/Header";
import caregiverimg from "../../assets/becameleft3.png";
import editimg from "../../assets/becamecareimgupload.png";
import uploadimg from "../../assets/uploadimg.png";
import { useNavigate } from "react-router-dom";
import { becamecaregiver3 } from "../../service/Mainservice";

const Becamecaregiver3 = () => {
  const [files, setFiles] = useState({
    preview: null,
    dl: null,
    passport: null,
    certificate: null,
  });
  const { preview, dl, passport, certificate } = files;
  const [load, setload] = useState(false);

  const [validerror, setvaliderror] = useState(null);

  const navigate = useNavigate();

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    setFiles((prevFiles) => ({
      ...prevFiles,
      [type]: file,
    }));
  };

  const removeFile = (type) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [type]: null,
    }));
  };

  const validate = () => {
    if (!dl) {
      setvaliderror("Please upload Driving license.");
    } else if (!passport) {
      setvaliderror("Please upload Permanent Resident/passport.");
    } else {
      setvaliderror(null);
      handlesubmit();
    }
  };

  const handlesubmit = async () => {
    setload(true);
    try {
      const response = await becamecaregiver3(files, 4);
      setFiles({
        preview: null,
        dl: null,
        passport: null,
        certificate: null,
      });
      navigate("/Daily_Availability");
      localStorage.setItem("screen", "4");

      setload(false);
    } catch (error) {
      console.log(error.message);
      setload(false);
    }
  };

  return (
    <>
      <Header title="Become a Companion" headerbg="becamecaregiverbg2" />
      <div className="Bookcaregiverdiv">
        <div className="Bookcaregiverdiv_left">
          <h2>Upload Documents</h2>
          {/* <div className="Becomecare_profileimg">
            {preview ? (
              <div>
                <img
                  src={URL.createObjectURL(preview)}
                  className="Becomecareprofile_previewimg"
                />

                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  className="Becomecaredelete_img"
                  onClick={() => removeFile("preview")}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11"
                    cy="11"
                    r="10.5"
                    fill="#FF3868"
                    stroke="white"
                  />
                  <path d="M15 7L7 15" stroke="white" stroke-linecap="round" />
                  <path d="M15 15L7 7" stroke="white" stroke-linecap="round" />
                </svg>
              </div>
            ) : (
              <label htmlFor="preview">
                <img src={editimg} alt="" className="Becomecareprofile_img" />
              </label>
            )}
            <input
              type="file"
              id="preview"
              accept="image/*"
              onChange={(e) => handleFileChange(e, "preview")}
              name="profile_pic"
            />
          </div> */}
          <p className="Becomecare_uploadtitle">Driving License</p>
          <div className="Becomecare_uploadimg">
            {dl ? (
              <div className="Becomecareprofile_img">
                <img
                  src={URL.createObjectURL(dl)}
                  className="Becomecareprofile_img"
                />

                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  className="Becomecaredelete_img"
                  onClick={() => removeFile("dl")}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11"
                    cy="11"
                    r="10.5"
                    fill="#FF3868"
                    stroke="white"
                  />
                  <path d="M15 7L7 15" stroke="white" stroke-linecap="round" />
                  <path d="M15 15L7 7" stroke="white" stroke-linecap="round" />
                </svg>
              </div>
            ) : (
              <div>
                <label htmlFor="dl">
                  <div className="becamecare_uploadbtn">
                    <img
                      src={uploadimg}
                      alt=""
                      className="Becomecareprofile_img"
                    />
                    <p>
                      <span>Browse</span> or Drag and drop here
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  id="dl"
                  accept="image/*"
                  onChange={(e) => {
                    handleFileChange(e, "dl");
                    setvaliderror("");
                  }}
                  name="dl"
                />
              </div>
            )}
          </div>
          <p className="Becomecare_uploadtitle">
            Permanent Resident Or U.S. Passport
          </p>
          <div className="Becomecare_uploadimg">
            {passport ? (
              <div className="Becomecareprofile_img">
                <img
                  src={URL.createObjectURL(passport)}
                  className="Becomecareprofile_img"
                />

                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  className="Becomecaredelete_img"
                  onClick={() => removeFile("passport")}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11"
                    cy="11"
                    r="10.5"
                    fill="#FF3868"
                    stroke="white"
                  />
                  <path d="M15 7L7 15" stroke="white" stroke-linecap="round" />
                  <path d="M15 15L7 7" stroke="white" stroke-linecap="round" />
                </svg>
              </div>
            ) : (
              <div>
                <label htmlFor="passport">
                  <div className="becamecare_uploadbtn">
                    <img
                      src={uploadimg}
                      alt=""
                      className="Becomecareprofile_img"
                    />
                    <p>
                      <span>Browse</span> or Drag and drop here
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  id="passport"
                  accept="image/*"
                  onChange={(e) => {
                    handleFileChange(e, "passport");
                    setvaliderror("");
                  }}
                  name="passport"
                />
              </div>
            )}
          </div>
          <p className="Becomecare_uploadtitle">Certifications</p>
          <div className="Becomecare_uploadimg">
            {certificate ? (
              <div className="Becomecareprofile_img">
                <img
                  src={URL.createObjectURL(certificate)}
                  className="Becomecareprofile_img"
                />

                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  className="Becomecaredelete_img"
                  onClick={() => {
                    removeFile("certificate");
                    setvaliderror("");
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11"
                    cy="11"
                    r="10.5"
                    fill="#FF3868"
                    stroke="white"
                  />
                  <path d="M15 7L7 15" stroke="white" stroke-linecap="round" />
                  <path d="M15 15L7 7" stroke="white" stroke-linecap="round" />
                </svg>
              </div>
            ) : (
              <div>
                <label htmlFor="certificate">
                  <div className="becamecare_uploadbtn">
                    <img
                      src={uploadimg}
                      alt=""
                      className="Becomecareprofile_img"
                    />
                    <p>
                      <span>Browse</span> or Drag and drop here
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  id="certificate"
                  accept="image/*"
                  onChange={(e) => {
                    handleFileChange(e, "certificate");
                    setvaliderror("");
                  }}
                  name="certificate"
                />
              </div>
            )}
          </div>
          {validerror !== null ? (
            <div className="validation_error" style={{ margin: "2px 0" }}>
              {validerror}
            </div>
          ) : null}

          <button
            className="Bookcaregiver_button"
            onClick={validate}
            disabled={load}
          >
            {load ? "Loading....." : "Submit"}
          </button>
        </div>
        <img src={caregiverimg} alt="" className="Bookcaregiverimg" />
      </div>
    </>
  );
};
export default Becamecaregiver3;
