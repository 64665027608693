import Layout from "../Layout";
import Bookcaregiver from "../componant/Customer/Bookcaregiver";
import Bookcaregiver2 from "../componant/Customer/Bookcaregiver2";
import Bookcaregiver3 from "../componant/Customer/Bookcaregiver3";
import Bookingconfirm from "../componant/Customer/BookingConfirm";
import Editprofile from "../componant/Profile/EditProfile";
import Upcomingbook from "../componant/Profile/MyBookingupcoming";
import Completedbook from "../componant/Profile/MyBookingcompleted";
import Becamecaregiver3 from "../componant/Provider/Becamecare3";
import Becamecaregiver from "../componant/Provider/Becomecare";
import Becamecaregiver2 from "../componant/Provider/Becomecare2";
import DailyAvailability from "../componant/Provider/Dailyavailabity";
import Addcard from "../componant/Profile/Addcard";
import Mybilling from "../componant/Profile/Mybilling";
import MyAddress from "../componant/Profile/MyAddress";
import Payment from "../componant/Profile/Payment";
import PaymentDetails from "../componant/Profile/PaymentDetails";
import ProtectedRoute from "../Protected Routes/ProtectedRoute";
import Referfriend from "../componant/Profile/Referafriend";
import CaregiverUpcomingbook from "../componant/Profile/CaregiverBookingupcoming";
import CaregiverCompletedbook from "../componant/Profile/CaregiverBookingcompleted";
import Earning from "../componant/Profile/Earning";
import HelpCenter from "../componant/Profile/HelpCenter";
import Booktype from "../componant/Customer/Booktype";
import Recurring from "../componant/Customer/RecurringBooking";
import Flexible from "../componant/Customer/FlexibleBooking";

const MainRoutes = {
  element: <Layout />,
  children: [
    {
      path: "/AddCard",
      element: <Addcard />,
    },

    {
      path: "/ClientDetails",
      element: <Bookcaregiver />,
    },
    {
      path: "/Billing&carddetail",
      element: <Bookcaregiver2 />,
    },
    {
      path: "/Booking_Type",
      element: <Booktype />,
    },
    {
      path: "/Bookcaregiver_Fixeddate",
      element: <Bookcaregiver3 />,
    },
    {
      path: "/Bookcaregiver_Recurring",
      element: <Recurring />,
    },
    {
      path: "/Bookcaregiver_Multipledate",
      element: <Flexible />,
    },
    {
      path: "/Booking Confirm",
      element: <Bookingconfirm />,
    },
    {
      path: "/MybookingUpcoming",
      element: <Upcomingbook />,
    },
    {
      path: "/MybookingCompleted",
      element: <Completedbook />,
    },
    {
      path: "/MyBilling",
      element: <Mybilling />,
    },
    {
      path: "/MyAddress",
      element: <MyAddress />,
    },
    {
      path: "/BecameaCompanion",
      element: <Becamecaregiver />,
    },
    {
      path: "/BecameaCompanion2",
      element: <Becamecaregiver2 />,
    },
    {
      path: "/BecameaCompanion3",
      element: <Becamecaregiver3 />,
    },
    {
      path: "/Daily_Availability",
      element: <DailyAvailability />,
    },
    {
      path: "/EditProfile",
      element: <Editprofile />,
    },
    {
      path: "/CompanionUpcomingbook",
      element: <CaregiverUpcomingbook />,
    },
    {
      path: "/CompanionCompletedbook",
      element: <CaregiverCompletedbook />,
    },

    {
      path: "/EarningorStubs",
      element: <Earning />,
    },
    {
      path: "/ReferaFriend",
      element: <Referfriend />,
    },
    {
      path: "/Payments",
      element: <Payment />,
    },
    {
      path: "/PaymentDetails",
      element: <PaymentDetails />,
    },
    {
      path: "/HelpCenter",
      element: <HelpCenter />,
    },
  ],
};

const MainRoutesWithProtection = {
  element: <Layout />,
  children: MainRoutes.children.map((route) => ({
    ...route,
    element: <ProtectedRoute>{route.element}</ProtectedRoute>,
  })),
};

export default MainRoutesWithProtection;
