import { useLocation, useNavigate } from "react-router-dom";
import "./Booktype.css";
import Header from "../../Layout/Header";
import caregiverimg from "../../assets/caregiverleft3.png";
import { useEffect, useState } from "react";
import {
  bookcaregiver1,
  clientinfo,
  couponlist,
  serviceprice,
  servicetype,
} from "../../service/Mainservice";
import Map from "./SMap3"; //-----------
import { useFormik } from "formik";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 100) {
    errors.name = "Must be 100 characters or less";
  }
  if (!values.birthdate) {
    errors.birthdate = "Required";
  }
  if (!values.startdate) {
    errors.startdate = "Required";
  }
  if (values.day.length === 0) {
    errors.day = "Must be Select 1 day";
  }

  return errors;
};

const Recurring = () => {
  const [time, setime] = useState("9:00 AM");
  const [hour, sethour] = useState(2);
  const [sload, setsload] = useState(false);
  const [select, setselect] = useState();

  const [service, setservice] = useState();
  const [serviceIds, setserviceIds] = useState([]);
  const [clienti, setclienti] = useState(); //-------
  const [latlng, setlatlng] = useState();
  const [markerPlace, setmarkerPlace] = useState();
  const [placeerror, setplaceerror] = useState(); //-------
  const [serviceerror, setserviceerror] = useState(); //-------

  const [basic, setbasic] = useState();
  const [est, setest] = useState();
  const [cid, setcid] = useState();

  const [cname, setcname] = useState();
  const [coupon, setcoupon] = useState();
  const [viewc, setviewc] = useState(false);
  const [couponmsg, setcouponmsg] = useState("");

  const weekname = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const location = useLocation();
  const type = location.state.type;
  const navigate = useNavigate();
  const moment = require("moment");

  useEffect(() => {
    setest();
    getclientinfo();
    getservice();
  }, []);

  useEffect(() => {
    handleprice();
    setcid();
    setcname();
  }, [basic]);

  useEffect(() => {
    handleprice();
  }, [hour, cid]);

  const getclientinfo = async () => {
    try {
      const response = await clientinfo();
      setclienti(response.data.data);
      formik.setValues({
        name: response.data.data.name,
        birthdate: response.data.data.birth_date,
        startdate: "",
        day: ["Mon"],
      });
      setlatlng({
        lat: response.data.data.billing_lat,
        long: response.data.data?.billing_long,
      });
      setmarkerPlace(response.data.data?.billing_address);
    } catch (error) {
      console.log(error);
    }
  };

  // ---------------------------------------------------
  const getservice = async () => {
    try {
      const response = await servicetype();
      setservice(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlesubmit = async (values, { resetForm }) => {
    console.log(markerPlace);
    if (markerPlace === undefined) {
      setplaceerror("Please select address");
    } else if (placeerror) {
      setplaceerror("You need to select valid place");
    } else if (serviceIds.length === 0) {
      setserviceerror("Please select services");
    } else {
      setsload(true);
      try {
        const apidata = {
          values,
          lat: latlng.lat,
          long: latlng.lng,
          address: markerPlace,
          type: type,
        };
        const response = await bookcaregiver1(
          apidata,
          time,
          hour,
          select,
          serviceIds,
          basic,
          cid
        );
        navigate("/Booking Confirm", {
          state: {
            id: response.data.data,
          },
        });
        setsload(false);
      } catch (error) {
        console.log(error);
        setsload(false);
      }
    }
  };
  const toggleServiceSelection = (e, serviceid) => {
    if (e.target.checked) {
      setserviceIds([...serviceIds, serviceid]);
    } else {
      setserviceIds((serviceIds) =>
        serviceIds.filter((id) => id !== serviceid)
      );
    }
  };
  const availablecoupenlist = async () => {
    try {
      const response = await couponlist("2");
      setcoupon(response.data.data);
      if (!response.data.data) {
        setcouponmsg(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleprice = async () => {
    if (basic === undefined) {
      setest();
    } else {
      setest("Loading....");
      try {
        const response = await serviceprice(select, hour, basic);
        setest(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleselectedtypebasic = (e, data) => {
    setbasic(e.target.checked);
    if (e.target.checked) {
      setserviceIds(data.basic_services.map((d) => d.id));
    }
  };
  const handleselectedtypepremium = (e, data) => {
    setbasic(!e.target.checked);
    if (e.target.checked) {
      setserviceIds(data.premium_services.map((d) => d.id));
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      birthdate: "",
      startdate: "",
      day: ["Mon"],
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });
  const handleday = (day) => {
    formik.setFieldValue(
      "day",
      formik.values.day.includes(day)
        ? formik.values.day.filter((d) => d !== day)
        : [...formik.values.day, day]
    );
  };
  return (
    <>
      <Header title="Recurring Booking" headerbg="caregiverbg2" />
      <div className="Bookcaregiverdiv">
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <h2>Client Info</h2>

          <div className="Bookcaregiver_inputfield_divs">
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="name">Client Name</label>
              <input
                onChange={formik.handleChange}
                value={formik.values.name}
                className="Bookcaregiver_input"
                name="name"
                type="text"
                placeholder="Client Name"
              />
              {formik.errors.name ? (
                <div className="validation_error" style={{ margin: "2px 0" }}>
                  {formik.errors.name}
                </div>
              ) : null}
            </div>
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="name">Date of Birth</label>
              <input
                onChange={formik.handleChange}
                value={formik.values.birthdate}
                className="Bookcaregiver_input"
                name="birthdate"
                type="date"
                placeholder="Date of Birth"
                max={moment(new Date()).format().split("T")[0]}
              />
              {formik.errors.birthdate ? (
                <div className="validation_error" style={{ margin: "2px 0" }}>
                  {formik.errors.birthdate}
                </div>
              ) : null}
            </div>
          </div>

          <div className="Bookcaregiver_inputfield">
            <label htmlFor="address2"> Select Address</label>
            <Map
              setplaceerror={setplaceerror}
              setMarkerPlace={setmarkerPlace}
              data={clienti}
              setlatlng={setlatlng}
            />

            {placeerror ? (
              <div className="validation_error" style={{ margin: "2px 0" }}>
                {placeerror}
              </div>
            ) : null}
          </div>

          <div className="Bookcaregiver_inputfield">
            <label>Select Start Date</label>
            <input
              type="date"
              onChange={formik.handleChange}
              value={formik.values.startdate}
              name="startdate"
              min={moment(new Date()).format().split("T")[0]}
              className="Bookcaregiver_input"
            />
            {formik.errors.startdate ? (
              <div className="validation_error" style={{ margin: "2px 0" }}>
                {formik.errors.startdate}
              </div>
            ) : null}
          </div>
          <h3 className="Booktype_heading">
            What's Your preffered Booking schedule?
          </h3>
          <div className="days">
            {weekname.map((day) => (
              <p
                key={day}
                className={
                  formik.values.day.find((pre) => pre === day)
                    ? "daysname selectedday"
                    : "daysname"
                }
                onClick={() => handleday(day)}
              >
                {day}
              </p>
            ))}
          </div>
          {formik.errors.day ? (
            <div className="validation_error" style={{ margin: "2px 0" }}>
              {formik.errors.day}
            </div>
          ) : null}

          <p className="Bookcaregiver_service">Select Time</p>

          <p className="Bookcaregiver_info">Morning</p>
          <div className="Bookcaregiver_timslot">
            <li
              className={time === "9:00 AM" ? "selctedtimeslot" : ""}
              onClick={() => setime("9:00 AM")}
            >
              9:00 AM
            </li>
            <li
              className={time === "9:30 AM" ? "selctedtimeslot" : ""}
              onClick={() => setime("9:30 AM")}
            >
              9:30 AM
            </li>
            <li
              className={time === "10:00 AM" ? "selctedtimeslot" : ""}
              onClick={() => setime("10:00 AM")}
            >
              10:00 AM
            </li>
            <li
              className={time === "10:30 AM" ? "selctedtimeslot" : ""}
              onClick={() => setime("10:30 AM")}
            >
              10:30 AM
            </li>
            <li
              className={time === "11:00 AM" ? "selctedtimeslot" : ""}
              onClick={() => setime("11:00 AM")}
            >
              11:00 AM
            </li>
            <li
              className={time === "11:30 AM" ? "selctedtimeslot" : ""}
              onClick={() => setime("11:30 AM")}
            >
              11:30 AM
            </li>
          </div>
          <p className="Bookcaregiver_info">Afternoon</p>
          <div className="Bookcaregiver_timslot">
            <li
              className={time === "12:00 PM" ? "selctedtimeslot" : ""}
              onClick={() => setime("12:00 PM")}
            >
              12:00 PM
            </li>
            <li
              className={time === "12:30 PM" ? "selctedtimeslot" : ""}
              onClick={() => setime("12:30 PM")}
            >
              12:30 PM
            </li>
            <li
              className={time === "1:00 PM" ? "selctedtimeslot" : ""}
              onClick={() => setime("1:00 PM")}
            >
              01:00 PM
            </li>
            <li
              className={time === "1:30 PM" ? "selctedtimeslot" : ""}
              onClick={() => setime("1:30 PM")}
            >
              01:30 PM
            </li>
            <li
              className={time === "2:00 PM" ? "selctedtimeslot" : ""}
              onClick={() => setime("2:00 PM")}
            >
              02:00 PM
            </li>
            <li
              className={time === "2:30 PM" ? "selctedtimeslot" : ""}
              onClick={() => setime("2:30 PM")}
            >
              02:30 PM
            </li>
            <li
              className={time === "3:00 PM" ? "selctedtimeslot" : ""}
              onClick={() => setime("3:00 PM")}
            >
              03:00 PM
            </li>
            <li
              className={time === "3:30 PM" ? "selctedtimeslot" : ""}
              onClick={() => setime("3:30 PM")}
            >
              03:30 PM
            </li>
            <li
              className={time === "4:00 PM" ? "selctedtimeslot" : ""}
              onClick={() => setime("4:00 PM")}
            >
              04:00 PM
            </li>
          </div>
          <div className="Bookcaregiver_inputfield">
            <label htmlFor="name">
              How many hours <br /> (per session)
            </label>

            <select
              onChange={(e) => sethour(e.target.value)}
              value={hour}
              name="name"
              className="Bookcaregiver_input"
            >
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="18">18</option>
              <option value="24">24</option>
            </select>
          </div>
          <p className="Bookcaregiver_service">Services</p>
          {service?.map((data) => (
            <>
              <h2
                className="Bookcaregiver_servicename"
                onClick={() => {
                  setselect(data.id);
                  setbasic();
                }}
              >
                {data.name}
                <KeyboardArrowDownIcon />
              </h2>
              {select === data.id ? (
                <>
                  <div className="Bookcaregiver_servicetype">
                    <input
                      type="radio"
                      name="type"
                      id="basic"
                      onClick={(e) => {
                        handleselectedtypebasic(e, data);
                      }}
                    />
                    <label>Basic</label>
                  </div>
                  {basic !== undefined
                    ? basic &&
                      data.basic_services.map((service) => (
                        <div className="Bookcaregiver_check2">
                          <input
                            type="checkbox"
                            name="agree"
                            id={`agree_${service.id}`}
                            onChange={(e) =>
                              toggleServiceSelection(e, service.id)
                            }
                            checked={serviceIds.find((id) => id === service.id)}
                          />
                          <label
                            htmlFor="agree"
                            className="Bookcaregiver_check_label"
                          >
                            {service.name}
                          </label>
                        </div>
                      ))
                    : ""}

                  <div className="Bookcaregiver_servicetype">
                    <input
                      type="radio"
                      name="type"
                      id="premium"
                      onClick={(e) => {
                        handleselectedtypepremium(e, data);
                      }}
                    />
                    <label>
                      Premium{" "}
                      <span style={{ fontSize: "12px" }}>
                        (*Included basic)
                      </span>
                    </label>
                  </div>
                  {basic !== undefined
                    ? !basic &&
                      data.premium_services.map((service) => (
                        <div className="Bookcaregiver_check2">
                          <input
                            type="checkbox"
                            name="premium"
                            id={`premium_${service.id}`}
                            onChange={(e) =>
                              toggleServiceSelection(e, service.id)
                            }
                            checked={serviceIds.find((id) => id === service.id)}
                          />
                          <label
                            htmlFor="agree"
                            className="Bookcaregiver_check_label"
                          >
                            {service.name}
                          </label>
                        </div>
                      ))
                    : ""}
                </>
              ) : (
                ""
              )}
              <label />
            </>
          ))}
          {serviceerror ? (
            <div className="validation_error" style={{ margin: "2px 0" }}>
              {serviceerror}
            </div>
          ) : null}

          {est && (
            <>
              <p className="Bookcaregiver_service">Estimated Cost(per day)</p>
              <h3 className="Bookcaregiver_cost">${est.total_price}</h3>
            </>
          )}
          {est && (
            <div className="Bookcaregiver_input">
              <p>{cname ? cname : "Available coupon"}</p>
              {cname ? (
                <CloseIcon
                  sx={{ fontSize: "15px", cursor: "pointer" }}
                  onClick={() => {
                    setcid();
                    setcname();
                  }}
                />
              ) : (
                <p onClick={availablecoupenlist} style={{ cursor: "pointer" }}>
                  check
                </p>
              )}
            </div>
          )}
          {serviceIds.length > 0 && est && coupon ? (
            <div className="allcoupenlist">
              {coupon?.map((cdata, index) => (
                <div className="coupen_list">
                  <div>
                    {cdata?.discount_type === "1" ? (
                      <h1 className="coupen_title">
                        GET {cdata?.discount_percentage}% OFF up to{" "}
                        {cdata?.maximum_discount}
                      </h1>
                    ) : (
                      <h1 className="coupen_title">
                        FLAT ${cdata?.flat_discount} OFF
                      </h1>
                    )}
                    <p className="coupen_min">
                      Min Amount: ${cdata?.min_amount}
                    </p>

                    <div className="coupen_view">
                      <p className="coupen_name">{cdata?.coupon_name}</p>
                      {viewc[index] ? (
                        <p
                          onClick={() => setviewc({ ...viewc, [index]: false })}
                          className="coupen_viewhide"
                        >
                          Hide details
                        </p>
                      ) : (
                        <p
                          onClick={() => setviewc({ ...viewc, [index]: true })}
                          className="coupen_viewhide"
                        >
                          view details
                        </p>
                      )}
                    </div>
                    {viewc[index] && (
                      <ul className="coupen_details">
                        <li>{cdata?.terms}</li>
                        <li>Expired on {cdata?.end_date}</li>
                        <li>Min Amount ${cdata?.min_amount}</li>
                      </ul>
                    )}
                  </div>
                  <button
                    className="coupen_apply"
                    onClick={() => {
                      setcid(cdata.id);
                      setcname(cdata.coupon_name);
                    }}
                    type="button"
                    disabled={cdata.min_amount > est.total_price}
                  >
                    {cid === cdata.id ? "Applied" : "Tap To Apply"}
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="nodata">{couponmsg}</p>
          )}

          <button className="Bookcaregiver_button" type="submit">
            {sload ? "Loading....." : "Confirm Booking"}
          </button>
        </form>
        <img src={caregiverimg} alt="" className="Bookcaregiverimg" />
      </div>
    </>
  );
};
export default Recurring;
