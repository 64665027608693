import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import {
  useStripe,
  useElements,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeError } from "@stripe/stripe-js";

import "./Addcard.css";
import Header from "../../Layout/Header";
import Profilemenu from "./Profilemenu";
import { savecard } from "../../service/Mainservice";
import Loader from "../../Loader/Loader";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const Addcard = () => {
  const navigate = useNavigate();
  const [load, setload] = useState(false);

  const [error, seterror] = useState();
  const [success, setsuccess] = useState();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const closealert2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const stripe = useStripe();
  const elements = useElements();

  const cardElementOptions = {
    style: {
      base: {
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "15px",
        ":-webkit-autofill": {},
        "::placeholder": {},
      },
      invalid: {
        iconColor: "#FFC7EE",
        color: "#FFC7EE",
      },
    },
  };

  const backbtn = () => {
    navigate("/Homelist_Gridview");
  };

  const handlesubmit = async (e) => {
    setload(true);
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    if (!cardNumberElement) {
      console.log("Card number element not found.");
      return;
    }

    const { token, error } = await stripe.createToken(cardNumberElement);

    if (error) {
      seterror(error.message);
      setload(false);
    } else {
      const params = {
        token_id: token.id,
      };

      try {
        const response = await savecard(params);
        setsuccess(response.data.message);
        setOpen(true);
        cardNumberElement.clear();
        cardExpiryElement.clear();
        cardCvcElement.clear();
        setload(false);
      } catch (error) {
        console.log(error);
        setload(false);
        // if (error.response.data.code === 401) {
        //   localStorage.clear();
        //   navigate("/Login");
        // }
      }
    }
  };
  return (
    <>
      <Header title="Add Card" headerbg="addressbg" />
      <div className="editprofile_data">
        <Profilemenu />

        <div className="addcard_details">
          <form id="card" onSubmit={handlesubmit}>
            <div className="auth_inputfield">
              {/* <img src={usericon} alt="" /> */}
              <input
                required
                name="name"
                type="text"
                placeholder="Name on Card"
              />
            </div>

            <div className="auth_inputfield">
              {/* <img src={cardicon} alt="" /> */}

              <div style={{ width: "100%" }}>
                <CardNumberElement options={cardElementOptions} />
              </div>
            </div>
            <div className="addcard_date">
              <h3>Expiry Date</h3>
            </div>
            <div className="date_field">
              <div className="date_input">
                <div style={{ width: "100%" }}>
                  <CardExpiryElement options={cardElementOptions} />
                </div>
              </div>
              <div className="date_input">
                <div style={{ width: "100%" }}>
                  <CardCvcElement options={cardElementOptions} />
                </div>
              </div>
            </div>
            <span className="p">{Error}</span>
            <button type="submit" className="auth_submitbutton" disabled={load}>
              {load ? "loading..." : "submit"}
            </button>
          </form>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open2}
        autoHideDuration={3000}
        onClose={closealert2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
      {load && <Loader />}
    </>
  );
};
export default function stripe() {
  return (
    <Elements stripe={stripePromise}>
      <Addcard />
    </Elements>
  );
}
