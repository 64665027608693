import { useNavigate } from "react-router-dom";
import "./Error.css";

const Error = () => {
  const navigate = useNavigate();
  return (
    <div className="error_data">
      <h1 className="error_heading">Oops!</h1>
      <h3 className="error_title">404 - Page Not Found</h3>
      <button className="error_button" onClick={() => navigate("/")}>
        Go To Homepage
      </button>
    </div>
  );
};
export default Error;
