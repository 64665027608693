import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import profileimg from "../../assets/profileimg.png";
import "./Ratemodal.css";
import { useState } from "react";
import { ratecaregiver } from "../../service/Mainservice";

const style = {
  position: "absolute",
  top: { xs: "60%", sm: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "342px" },
  bgcolor: "var(--white)",
  p: 2,
  borderRadius: "10px",
  border: "none",
  height: "520px",
  textAlign: "center",
};

const Rate = ({ showRate, setShowRate, id, getcomplete }) => {
  const [value, setValue] = useState(2);
  const [review, setreview] = useState();

  const handleClose = () => {
    setShowRate(false);
  };
  const handlerate = async () => {
    try {
      const response = await ratecaregiver(id.id, value, review);
      getcomplete(1);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={showRate}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "rgb(0, 0, 0, 0.75)",
        }}
      >
        <Box sx={style}>
          <div className="Terms_titlediv">
            <div />
            <h1 className="Terms_title">Rate this Service</h1>
            <div />
          </div>
          <div>
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}/${id.caregiver_pic}`}
              alt="caretakerimg"
              className="rateprofile"
            />
            <p className="rateprofilename"> {id.caregiver_name}</p>
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              size="large"
              style={{ fontSize: "45px" }}
            />
            <div className="rate_reviewdiv">
              <label htmlFor="review">Review</label> <br />
              <textarea
                value={review}
                onChange={(e) => setreview(e.target.value)}
                name="review"
                id="review"
                cols={30}
                rows={10}
              ></textarea>
            </div>
          </div>
          <button className="rate_button" onClick={handlerate}>
            Submit
          </button>
        </Box>
      </Modal>
    </>
  );
};
export default Rate;
