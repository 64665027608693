import renderHTML from "react-render-html";

import { useEffect, useState } from "react";
import { privacy } from "../service/Authservice";
import Header from "../Layout/Header";

const Privacy = () => {
  const [data, setdata] = useState();

  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    try {
      const response = await privacy();
      console.log(response);
      const td = renderHTML(response.data.data);
      setdata(td);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header title="Privacy Policy" headerbg="becamecaregiverbg3" />
      <p className="terms-policy">{data} </p>
    </>
  );
};
export default Privacy;
