import { Link, useLocation } from "react-router-dom";
import Header from "../../Layout/Header";
import Profilemenu from "./Profilemenu";
import "./MyBooking.css";
import { useEffect, useState } from "react";
import {
  canclebooking,
  needhelp,
  upcomingbooking,
} from "../../service/Mainservice";
import { Alert, Snackbar } from "@mui/material";
import Needhelp from "./Needhelp";
import Canclebooking from "./Canclebooking";
import Loader from "../../Loader/Loader";
import Pagination from "../../Pagination/Pagination";

const Upcomingbook = () => {
  const [list, setlist] = useState();
  const [load, setload] = useState(true);
  const [need, setneed] = useState(false);
  const [cancle, setcancle] = useState(false);
  const [bookid, setbookid] = useState();
  const [cid, setcid] = useState();
  const [success, setsuccess] = useState();
  const [open, setOpen] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState();

  const location = useLocation();
  const path = location.pathname;
  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    getupcoming(1);
  }, []);
  const getupcoming = async (val) => {
    try {
      const response = await upcomingbooking(val);
      setlist(response.data.data);
      setcurrentPage(response.data.pagination.current_pages);
      settotalPages(response.data.pagination.total_pages);
      setload(false);
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };

  const handlecanclebooking = async (values, { resetForm }) => {
    try {
      const response = await canclebooking(values, cid);
      getupcoming();
      setcancle(false);
      setsuccess(response.data.message);
      setOpen(true);
      resetForm();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleneedhelp = async (values, { resetForm }) => {
    try {
      const response = await needhelp(values, bookid);
      getupcoming();
      setneed(false);
      setsuccess(response.data.message);
      setOpen(true);
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  const handleneed = (id) => {
    setbookid(id);
    setneed(true);
  };
  const handlecancle = (id) => {
    setcid(id);
    setcancle(true);
  };
  return (
    <>
      <Header title="My Booking" headerbg="upcomingbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">My Booking</h2>
          <div className="booking_status">
            <Link
              to="/MybookingUpcoming"
              className={
                path === "/MybookingUpcoming"
                  ? "booking_status_title selected_title"
                  : "booking_status_title"
              }
            >
              Upcoming
            </Link>
            <Link
              to="/MybookingCompleted"
              className={
                path === "/MybookingCompleted"
                  ? "booking_status_title selected_title"
                  : "booking_status_title"
              }
            >
              Completed
            </Link>
          </div>
          {list ? (
            <div>
              <div className="mybooking_cardlist">
                {list.map((data) => (
                  <div className="mybooking_card">
                    <div className="mybooking_cardup">
                      <div className="mybooking_cardinfo1">
                        <p>Request ID</p>
                        <h3>{data.request_id}</h3>
                      </div>
                      <div className="mybooking_cardinfo1">
                        <p>Booking Date</p>
                        <h3>{data.booking_date}</h3>
                      </div>
                      <div className="mybooking_cardinfo1">
                        <p>Request Date</p>
                        <h3>{data.request_date}</h3>
                      </div>
                    </div>

                    <div className="mybooking_cardup">
                      <div className="mybooking_cardinfo1">
                        <p>Time</p>
                        <h3>{data.booking_time}</h3>
                      </div>
                      <div className="mybooking_cardinfo1">
                        <p>Address</p>
                        <h3>{data.address}</h3>
                      </div>
                    </div>
                    {data.booking_status === "1" ? (
                      <div className="mybooking_assign">
                        <p className="mybooking_soon">
                          Admin will assign Companion soon.
                        </p>
                        <p
                          className="mybooking_help"
                          onClick={() => handlecancle(data.id)}
                        >
                          Cancel Booking
                        </p>
                      </div>
                    ) : data.booking_status === "2" ||
                      data.booking_status === "3" ||
                      data.booking_status === "4" ? (
                      <>
                        {" "}
                        <div className="mybooking_cardup">
                          <div className="mybooking_cardinfo">
                            <p>Companion Assigned</p>
                            <h3 className="mybooking_caretakerdata">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}${data.caregiver_pic}`}
                              />
                              {data.caregiver_name}
                            </h3>
                          </div>
                        </div>
                        {data.help_status === "1" ? (
                          <p
                            className="mybooking_help"
                            onClick={() => handleneed(data.id)}
                          >
                            Need help?
                          </p>
                        ) : data.help_status === "2" ? (
                          <p className="mybooking_help">Help Requested</p>
                        ) : (
                          <p className="mybooking_help">Help Closed</p>
                        )}
                      </>
                    ) : data.booking_status === "5" ? (
                      <p className="mybooking_help">Booking Cancelled</p>
                    ) : (
                      <p className="mybooking_help">
                        Pending Cancellation Approval
                      </p>
                    )}
                  </div>
                ))}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                getorderlist={getupcoming}
              />
            </div>
          ) : (
            <div className="blank_booking">No Upcoming Bookings</div>
          )}
        </div>
      </div>
      <Needhelp need={need} setneed={setneed} handleneedhelp={handleneedhelp} />
      <Canclebooking
        need={cancle}
        setneed={setcancle}
        handleneedhelp={handlecanclebooking}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      {load && <Loader />}
    </>
  );
};
export default Upcomingbook;
