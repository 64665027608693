import Header from "../../Layout/Header";
import "./Payment.css";
import Profilemenu from "./Profilemenu";
import { Alert, Snackbar } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { deletecard, getcard, selectcard } from "../../service/Mainservice";
import Loader from "../../Loader/Loader";

const PaymentDetails = () => {
  const [cardlist, setcardlist] = useState();
  const [showmenuoption, setshowmenuoption] = useState(false);
  const [success, setsuccess] = useState(false);
  const [load, setload] = useState(true);

  const [open, setOpen] = useState(false);
  const Navigate = useNavigate();

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    getcardlist();
  }, []);

  const getcardlist = async () => {
    try {
      const response = await getcard();
      setcardlist(response.data.data);
      setload(false);
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };
  const handledeletecard = async (id, index) => {
    setshowmenuoption({
      ...showmenuoption,
      [index]: !showmenuoption[index],
    });
    setload(true);
    try {
      setload(true);
      const response = await deletecard(id);
      getcardlist();
      setsuccess(response.data.message);
      setOpen(true);
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };
  const handleselectcard = async (id) => {
    try {
      const response = await selectcard(id);
      getcardlist();
      setsuccess(response.data.message);
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header title="Payments" headerbg="editbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">Payments Detail</h2>
          <div className="payment_cards">
            <div className="payment_allcards">
              {cardlist?.map((card, index) => (
                <div className="payment_cardinfo">
                  <img src={card.brand} alt="" />
                  XXXX XXXX XXXX {card.last4}
                  <div className="payment_cardselected">
                    {card.is_selected === 1 ? (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.99935 17.3334C4.39685 17.3334 0.666016 13.6025 0.666016 9.00002C0.666016 4.39752 4.39685 0.666687 8.99935 0.666687C13.6018 0.666687 17.3327 4.39752 17.3327 9.00002C17.3327 13.6025 13.6018 17.3334 8.99935 17.3334ZM8.16852 12.3334L14.0602 6.44085L12.8818 5.26252L8.16852 9.97669L5.81102 7.61919L4.63268 8.79752L8.16852 12.3334Z"
                          fill="#27BD23"
                        />
                      </svg>
                    ) : (
                      <div
                        className="payment_unselected"
                        onClick={() => handleselectcard(card.id)}
                      />
                    )}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setshowmenuoption({
                          ...showmenuoption,
                          [index]: !showmenuoption[index],
                        })
                      }
                    >
                      <g clip-path="url(#clip0_45_1168)">
                        <path
                          d="M12 3C11.175 3 10.5 3.675 10.5 4.5C10.5 5.325 11.175 6 12 6C12.825 6 13.5 5.325 13.5 4.5C13.5 3.675 12.825 3 12 3ZM12 18C11.175 18 10.5 18.675 10.5 19.5C10.5 20.325 11.175 21 12 21C12.825 21 13.5 20.325 13.5 19.5C13.5 18.675 12.825 18 12 18ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"
                          fill="#312920"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_45_1168">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {showmenuoption[index] && (
                      <div
                        className="cardmenuoption"
                        onClick={() => handledeletecard(card.id, index)}
                      >
                        <p>Delete</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <p className="payment_cardadd" onClick={() => Navigate("/AddCard")}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_49_460)">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_49_460">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Add New cards
            </p>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      {load && <Loader />}
    </>
  );
};
export default PaymentDetails;
