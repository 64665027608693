import React, { useRef, useState } from "react";
import { Autocomplete, LoadScriptNext } from "@react-google-maps/api";

const libraries = ["places"];

const Map = ({ setMarkerPlace, setplaceerror }) => {
  const [searchValue, setSearchValue] = useState("");
  const autoCompleteRef = useRef(null);

  const onPlaceChanged = () => {
    const place = autoCompleteRef.current.getPlace();
    if (!place?.geometry) {
      setSearchValue("");
      setplaceerror(`Invalid place`);
      setMarkerPlace(undefined);
      return;
    } else {
      const { lat, lng } = place.geometry.location;
      if (!lat || !lng) {
        console.error("Invalid location data:", place.geometry.location);
        return;
      }

      const latLng = { lat: lat(), lng: lng() };
      setMarkerPlace(place);
      setSearchValue();
      setplaceerror("");
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission or navigation
    }
  };

  return (
    <LoadScriptNext
      googleMapsApiKey="AIzaSyDb_u9uXPI3oJBv40IHjr5xgf4TvSET_gI"
      libraries={libraries}
    >
      <Autocomplete
        onLoad={(autoComplete) => {
          autoCompleteRef.current = autoComplete;
        }}
        onPlaceChanged={onPlaceChanged}
      >
        <div className="Bookcaregiver_inputfield">
          <input
            type="text"
            placeholder="Address"
            className="Bookcaregiver_input"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={handleKeyPress} // Handle Enter keypress
          />
        </div>
      </Autocomplete>
    </LoadScriptNext>
  );
};

export default Map;
