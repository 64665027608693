import { createContext, useContext, useState } from "react";

const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
  const [headerData, setHeaderData] = useState(() => {
    try {
      const storedData = localStorage.getItem("data");
      if (storedData) {
        return JSON.parse(storedData);
      }
      return {};
    } catch (e) {
      console.error("Failed to parse localStorage data:", e);
      return {};
    }
  });

  const updateHeaderData = (updatedData) => {
    setHeaderData((prevData) => {
      const newData = {
        ...prevData,
        ...updatedData,
      };
      localStorage.setItem("data", JSON.stringify(newData));
      return newData;
    });
  };

  return (
    <HeaderContext.Provider value={{ headerData, updateHeaderData }}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeaderData = () => {
  return useContext(HeaderContext);
};
