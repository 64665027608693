import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
// import renderHTML from "react-render-html";

// import { useEffect, useState } from "react";
// import { terms } from "../../service/Authservice";

const style = {
  position: "absolute",
  top: { xs: "60%", sm: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "482px" },
  bgcolor: "var(--white)",
  p: 1,
  borderRadius: "10px",
  outline: "none",
  border: "none",
  height: "510px",
  textAlign: "center",
};

const Agree = ({ showTerms, setShowTerms }) => {
  // const [data, setdata] = useState();

  const handleClose = () => {
    setShowTerms(false);
  };

  // useEffect(() => {
  //   getdata();
  // }, []);

  // const getdata = async () => {
  //   try {
  //     const response = await terms();
  //     const td = renderHTML(response.data.data);

  //     setdata(td);
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      <Modal
        open={showTerms}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "rgb(0, 0, 0, 0.75)",
        }}
      >
        <Box sx={style}>
          <div className="Terms_titlediv">
            <div />
            <h1 className="Terms_title">Employee Agreement</h1>
            <CloseIcon
              className="closepopup"
              onClick={() => setShowTerms(false)}
            />
          </div>
          <p className="Terms_data">
            Agreement Acknowledge that you have read and understood the terms
            and conditions of this Platform and agree to abide by them. Your
            access to and use of the Platform is conditioned on your acceptance
            of and compliance with these Terms. These Terms apply to all
            visitors, users and others who access or use the Platform. By
            accessing or using the service you agree to be bound by these Terms.
            If you disagree with any part of the terms, then you may not access
            the service. Intellectual Property <br />
            The Platform and its original content, features and functionality
            are and will remain the exclusive property of i-Companion Care LLC
            and its licensors. Links to Other Web Sites
            <br />
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by i-Companion Care LLC.
            i-Companion Care LLC has no control over, and assumes no
            responsibility for, the content, privacy policies, or practices of
            any third-party web sites or services. You further acknowledge and
            agree that i-Companion Care LLC shall not be responsible or liable,
            directly or indirectly, for any damage or loss caused or alleged to
            be caused by or in connection with use of or reliance on any such
            content, goods or services available on or through any such web
            sites or services. We strongly advise you to read the terms and
            conditions and privacy policies of any third-party web sites or
            services that you visit. Termination
            <br />
            We may terminate or suspend access to our Platform immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if you breach the Terms. Cancellation
            Policy
            <br />
            The client agrees to provide at least 24 hours' notice for any
            cancellation or rescheduling of services. Failure to provide
            adequate notice may result in a cancellation fee of $40. The service
            provider reserves the right to cancel or reschedule services due to
            unforeseen circumstances such as illness or emergency. Disclaimer
            <br />
            Your use of the Platform is at your sole risk. The service is
            provided on an “AS IS” and “AS AVAILABLE” basis. The service is
            provided without warranties of any kind, whether express or implied.
            Governing Law
            <br />
            These Terms shall be governed and construed in accordance with the
            laws of United States without regard to its conflict of law
            provisions. Our failure to enforce any right or provision of these
            Terms will not be considered a waiver of those rights. If any
            provision of these Terms is held to be invalid or unenforceable by a
            court, the remaining provisions of these Terms will remain in
            effect. These Terms constitute the entire agreement between us
            regarding our Platform and supersede and replace any prior
            agreements we might have between us regarding the services. Changes
            <br />
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will try to
            provide at least 10 days’ notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion. By continuing to access or use our Platform after
            those revisions become effective, you agree to be bound by the
            revised terms. If you do not agree to the new terms, please stop
            using the service. Confidentiality
            <br />
            Both parties agree to maintain the confidentiality of any personal
            or sensitive information shared during the provision of services.
            Supplies
            <br />
            The care provider will utilize supplies provided by the client for
            the provision of care. The care provider does not provide nor stock
            supplies. Liability
            <br />
            The service provider shall not be liable for any damage or injuries
            that occur on the client's property, unless caused by the negligence
            of the care provider.{" "}
          </p>
        </Box>
      </Modal>
    </>
  );
};
export default Agree;
