import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import loginimg from "../../assets/emailverify.jpg";
import loginimg2 from "../../assets/emailverify.jpg";
import { forgetpassword, signupemail } from "../../service/Authservice";
import { Alert, Snackbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const SignUpemail = () => {
  const [token, settoken] = useState();
  const [error, seterror] = useState(false);
  const [open, setOpen] = useState(false);
  const [load, setload] = useState();

  const unshowref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const typename = location.state?.type;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (unshowref.current && !unshowref.current.contains(event.target)) {
        navigate("/");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handlesubmit = async (values, { resetForm }) => {
    setload(true);
    try {
      const response = await signupemail(values.email);
      setload(false);
      navigate("/Emailverify", {
        state: {
          token: response.data.token,
          type: typename,
          email: formik.values.email,
        },
      });
      resetForm();
    } catch (error) {
      setload(false);
      setOpen(true);
      seterror(error.message);
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });

  return (
    <>
      <div className="Auth_BG">
        <div className="Auth_main" ref={unshowref}>
          <img
            src={typename === "1" ? loginimg2 : loginimg}
            alt=""
            className="Auth_img"
          />
          <div className="Auth_details">
            <h1 className="auth_title">
              {typename === "1" ? "Client" : "Companion"} Email Registration
            </h1>
            <p className="auth_info">
              Please enter the email address you ‘d like to register account.
            </p>

            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="auth_inputfield">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_21_537)">
                    <path
                      d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
                      fill="#312920"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_21_537">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  type="email"
                  placeholder="Email"
                />
              </div>
              {formik.errors.email ? (
                <div className="validation_error">{formik.errors.email}</div>
              ) : null}
              <button
                type="submit"
                className="auth_submitbutton"
                disabled={load}
              >
                {load ? "Loading..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};
export default SignUpemail;
