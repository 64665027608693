import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const userToken = localStorage.getItem("ntk");
    return !!userToken;
  });

  useEffect(() => {
    const userToken = localStorage.getItem("ntk");
    if (!userToken) {
      setIsAuthenticated(false);
      navigate("/");
    } else {
      setIsAuthenticated(true);
    }
  }, [navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
