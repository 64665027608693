import React, { useEffect, useRef, useState } from "react";
import "./Otp.css";
import { Alert, Snackbar } from "@mui/material";
import OTPInput from "otp-input-react";

import loginimg from "../../assets/Forget.png";
import { otpverify } from "../../service/Authservice";
import { useLocation, useNavigate } from "react-router-dom";

const Code = () => {
  const [otp, setotp] = useState(Number);
  const [open, setOpen] = useState(false);
  const [error, seterror] = useState(false);
  const [otperror, setotperror] = useState(false);
  const [load, setload] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const token = location.state?.token;
  const unshowref = useRef();

  useEffect(() => {
    if (!token) {
      navigate("/ForgetPassword");
    }
    const handleClickOutside = (event) => {
      if (unshowref.current && !unshowref.current.contains(event.target)) {
        navigate("/");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleverify = async () => {
    if (otp.length === 0) {
      setotperror("Required");
    } else if (otp.length !== 6) {
      setotperror("OTP must be 6 Characters");
    } else {
      setotperror("");
      setload(true);
      try {
        const data = await otpverify(otp, token);
        setload(false);
        navigate("/ResetPassword", {
          state: {
            token: data.data.token,
          },
        });
        setotp();
      } catch (error) {
        seterror(error.message);
        setOpen(true);
        setload(false);
      }
    }
  };

  return (
    <>
      <div className="Auth_BG">
        <div className="Auth_main" ref={unshowref}>
          <img src={loginimg} alt="" className="Auth_img" />
          <div className="Auth_details">
            <h1 className="Scanner_title">Enter OTP Code</h1>
            <OTPInput
              value={otp}
              onChange={setotp}
              className="Scanner_input"
              OTPLength={6}
              otpType="number"
              inputClassName="otp_input"
              // inputStyles={{
              //   borderRadius: 6,
              //   outline: 0,
              //   border: "1px solid var(--black)",
              //   padding: "10px 8px",
              //   marginRight: 10,
              //   fontSize: "18px",
              // }}
              resendOTP={{}}
              //  secure
            />
            <div className="validation_error">{otperror}</div>

            <p className="note_mark_title">Can't Recieve OTP?</p>
            <p className="note_mark">
              Please check your spam/junk mail to ensure OTP(authenticating your
              email) code
            </p>

            <button
              disabled={load}
              className="auth_submitbutton"
              onClick={handleverify}
            >
              {load ? "Loading...." : "Submit"}
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Code;
