import Header from "../../Layout/Header";
import "./Payment.css";
import Profilemenu from "./Profilemenu";
import { Withdraw, costtotal, stripeurl } from "../../service/Mainservice";
import { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import KYCPopup from "./KYC";
import Loader from "../../Loader/Loader";

const Payment = () => {
  const [cost, setcost] = useState();
  const [kyc, setkyc] = useState(false);
  const [load, setload] = useState(true);

  const [url, seturl] = useState(false);
  const [error, seterror] = useState(false);
  const [success, setsuccess] = useState(false);
  const [open, setopen] = useState();
  const [open2, setopen2] = useState();

  useEffect(() => {
    getcost();
    checkstripe();
  }, []);

  const checkstripe = async () => {
    const response = await stripeurl();
    console.log(response);
    if (response.data.url) {
      seturl(response.data.url);
      setkyc(true);
    }
  };

  const getcost = async () => {
    try {
      const response = await costtotal();
      setcost(response.data.data);
      setload(false);
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };
  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopen(false);
  };
  const closealert2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopen2(false);
  };

  const handlewithdraw = async () => {
    setload(true);
    try {
      const response = await Withdraw();
      setload(false);

      if (response.data.url !== null) {
        seterror(response.data.message);
        setopen2(true);
        setkyc(true);
        seturl(response.data.url);
      } else {
        setcost(0);
        setsuccess(response.data.message);
        setopen(true);
      }
    } catch (error) {
      setload(false);
      seterror(error.message);
      setopen2(true);
    }
  };

  return (
    <>
      <Header title="Payments" headerbg="editbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">Payments</h2>
          <div className="payment_value">
            <div className="payment_value_info">
              <h1>${cost}</h1>
              <p>Total Earning</p>
            </div>
            {/* <button className="payment_button" onClick={handlewithdraw}>
              Withdraw
            </button> */}
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open2}
        autoHideDuration={3000}
        onClose={closealert2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
      <KYCPopup isOpen={kyc} setisOpen={setkyc} url={url} />
      {load && <Loader />}
    </>
  );
};
export default Payment;
