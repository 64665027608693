import Header from "../../Layout/Header";
import Profilemenu from "./Profilemenu";
import billingprofile from "../../assets/billingprofile.png";
import "./Mybilling.css";
import { useEffect, useState } from "react";
import { billinglist, generateinvoice } from "../../service/Mainservice";
import Loader from "../../Loader/Loader";

const Mybilling = () => {
  const [list, setlist] = useState();
  const [load, setload] = useState(true);
  const [download, setdownload] = useState();

  useEffect(() => {
    getbilling(1);
  }, []);

  const getbilling = async (val) => {
    try {
      const response = await billinglist(val);
      setlist(response.data.data);
      setload(false);
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };
  const handledownload = async (id) => {
    setdownload(id);
    try {
      const response = await generateinvoice(id);
      setdownload(false);

      // Create a Blob object from the PDF data
      const blob = new Blob([response], {
        type: "application/pdf",
      });
      // Create a temporary URL for the Blob
      const url = URL.createObjectURL(blob);
      //  Create an anchor element
      const a = document.createElement("a");

      // Set the anchor's href to the Blob URL and specify the filename for download
      a.href = url;
      a.download = "invoice.pdf";

      // Simulate a click on the anchor element to trigger the download
      a.click();

      // Clean up by revoking the Blob URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      setdownload(false);
    }
  };
  const downloadFile = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Header title="My Billing" headerbg="billingbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">My Billing</h2>
          <div className="bill_table_over">
            <table className="bill_table">
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  <th>Date of Service</th>
                  <th>Companion</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list?.map((data) => (
                  <tr>
                    <td>{data.invoice_id}</td>
                    <td>{data.date_of_service}</td>
                    <td className="billing_profilediv">
                      {data.caregiver_name === null ? (
                        "Assign soon"
                      ) : (
                        <div className="billing_profileinfo">
                          {" "}
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              data.caregiver_pic
                            }
                            alt=""
                          />
                          {data.caregiver_name}
                        </div>
                      )}
                    </td>
                    <td>
                      <button
                        className="billing_button"
                        onClick={() => handledownload(data.id)}
                        disabled={download === data.id}
                      >
                        {download === data.id ? "Downloading..." : "Download"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {load && <Loader />}
    </>
  );
};
export default Mybilling;
