import { useRoutes } from "react-router-dom";

import LandingRoutes from "./LandingRoutes";
import MainRoutesWithProtection from "./MainRoutes";
import MainRoutes from "./MainRoutes";
import Login from "../componant/Auth/Login";
import Choosetyp from "../componant/Auth/Choosetype";
import Register from "../componant/Auth/Register";
import ForgetPassword from "../componant/Auth/ForgetPassword";
import Code from "../componant/Auth/OTPverify";
import ResetPassword from "../componant/Auth/ResetPassword";
import Error from "../error";
import SignUpemail from "../componant/Auth/SignUpemail";
import Emailverify from "../componant/Auth/Emailverify";

export default function Router() {
  return useRoutes([
    {
      path: "/*",
      element: <Error />,
    },
    MainRoutes,
    LandingRoutes,
    {
      path: "/Login",
      element: <Login />,
    },
    {
      path: "/ChooseType",
      element: <Choosetyp />,
    },
    {
      path: "/SignUpemail",
      element: <SignUpemail />,
    },
    {
      path: "/EmailVerify",
      element: <Emailverify />,
    },
    {
      path: "/Register",
      element: <Register />,
    },
    {
      path: "/ForgetPassword",
      element: <ForgetPassword />,
    },
    {
      path: "/OTPVerify",
      element: <Code />,
    },
    {
      path: "/ResetPassword",
      element: <ResetPassword />,
    },
  ]);
}
