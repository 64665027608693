import Header from "../../Layout/Header";
import "./EditProfile.css";
import Profilemenu from "./Profilemenu";
import "./MyAddress.css";
import { useEffect, useState } from "react";
import {
  billingaddress,
  updatebillingaddress,
} from "../../service/Mainservice";
import { useFormik } from "formik";
import Map from "../Customer/Map";

const validate = (values) => {
  const errors = {};

  if (!values.zipcode) {
    errors.zipcode = "Required";
  }
  if (!values.city) {
    errors.city = "Required";
  }

  return errors;
};

const MyAddress = () => {
  const [data, setdata] = useState();
  const [city, setcity] = useState();
  const [code, setcode] = useState();
  const [edit, setedit] = useState();
  const [load, setload] = useState();
  const [markerPlace, setmarkerPlace] = useState();
  const [placeerror, setplaceerror] = useState();

  useEffect(() => {
    getaddress();
  }, []);

  useEffect(() => {
    formik.setFieldValue("zipcode", code);
    formik.setFieldValue("city", city);
  }, [markerPlace]);

  const getaddress = async () => {
    try {
      const response = await billingaddress();
      setdata(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlesubmit = async (values, { resetForm }) => {
    console.log(markerPlace);
    if (markerPlace === undefined) {
      setplaceerror("Please select address");
    } else if (placeerror) {
      setplaceerror("You need to select valid place");
    } else {
      const { lat, lng } = markerPlace.geometry.location;
      console.log(lat, lng);
      try {
        const data = {
          values,
          billinglat: lat(),
          billinglong: lng(),
          billingaddress: markerPlace.formatted_address,
        };

        const response = await updatebillingaddress(data);
        console.log(response);
        setedit(false);
        getaddress();
        setload(false);
        resetForm();
      } catch (error) {
        console.log(error);
        setload(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      zipcode: "",
      city: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });

  return (
    <>
      <Header title="My Addresses" headerbg="addressbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">My Addresses</h2>

          {!edit ? (
            <div className="address_namediv">
              <div className="address_boxdiv">
                <div className="address_box">
                  <h4 className="address_title">{data?.city}</h4>
                  <p className="address_titlevalue">
                    {" "}
                    {data?.billing_address + " " + data?.zipcode}{" "}
                  </p>
                </div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setedit(true)}
                  style={{ cursor: "pointer" }}
                >
                  <g clip-path="url(#clip0_29_1586)">
                    <path
                      d="M6.414 16L16.556 5.85802L15.142 4.44402L5 14.586V16H6.414ZM7.243 18H3V13.757L14.435 2.32202C14.6225 2.13455 14.8768 2.02924 15.142 2.02924C15.4072 2.02924 15.6615 2.13455 15.849 2.32202L18.678 5.15102C18.8655 5.33855 18.9708 5.59286 18.9708 5.85802C18.9708 6.12319 18.8655 6.37749 18.678 6.56502L7.243 18ZM3 20H21V22H3V20Z"
                      fill="#312920"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_29_1586">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="address1">Address</label>
                <Map
                  setMarkerPlace={setmarkerPlace}
                  setplaceerror={setplaceerror}
                  setcity={setcity}
                  setcode={setcode}
                />

                {placeerror ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {placeerror}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield_divs">
                <div className="Bookcaregiver_inputfield">
                  <label htmlFor="zipcode">Zip code</label>
                  <input
                    onChange={formik.handleChange}
                    value={formik.values.zipcode}
                    className="Bookcaregiver_input"
                    name="zipcode"
                    type="text"
                    placeholder="Zip code"
                  />
                  {formik.errors.zipcode ? (
                    <div
                      className="validation_error"
                      style={{ margin: "2px 0" }}
                    >
                      {formik.errors.zipcode}
                    </div>
                  ) : null}
                </div>
                <div className="Bookcaregiver_inputfield">
                  <label htmlFor="city">City</label>
                  <input
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    className="Bookcaregiver_input"
                    name="city"
                    type="text"
                    placeholder="City"
                  />
                  {formik.errors.city ? (
                    <div
                      className="validation_error"
                      style={{ margin: "2px 0" }}
                    >
                      {formik.errors.city}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="Bookcaregiver_btngrp">
                <button
                  className="Bookcaregiver_prvbutton"
                  onClick={() => setedit(false)}
                >
                  Cancel
                </button>

                <button className="Bookcaregiver_button" type="submit">
                  {load ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};
export default MyAddress;
