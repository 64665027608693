import Header from "../../Layout/Header";
import "./EditProfile.css";
import Profilemenu from "./Profilemenu";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { contact } from "../../service/Mainservice";
import Loader from "../../Loader/Loader";
import { useFormik } from "formik";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 100) {
    errors.name = "Must be 100 characters or less";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.message) {
    errors.message = "Required";
  } else if (values.message.length > 200) {
    errors.message = "Must be 100 characters or less";
  }
  return errors;
};

const HelpCenter = () => {
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setsuccess] = useState();

  const closealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handlesubmit = async (values, { resetForm }) => {
    setload(true);
    try {
      const response = await contact(values);
      setsuccess(response.data.message);
      setOpen(true);
      setload(false);
      resetForm();
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });

  return (
    <>
      <Header title="Help Center" headerbg="contactbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">Help Center</h2>
          <form
            style={{ marginTop: "35px" }}
            className="helpcenter_form"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <div className="Bookcaregiver_inputfield_divs">
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Name</label>
                <input
                  // onChange={handlechange}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className="Bookcaregiver_input"
                  name="name"
                  type="text"
                  placeholder="Name"
                />
                {formik.errors.name ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="Bookcaregiver_inputfield">
                <label htmlFor="name">Email</label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  type="text"
                  placeholder="Email"
                  className="Bookcaregiver_input"
                />
                {formik.errors.email ? (
                  <div className="validation_error" style={{ margin: "2px 0" }}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="name">Message</label>
              <textarea
                onChange={formik.handleChange}
                value={formik.values.message}
                cols={15}
                name="message"
                placeholder="Write Here"
                className="Bookcaregiver_input"
              />
              {formik.errors.message ? (
                <div className="validation_error" style={{ margin: "2px 0" }}>
                  {formik.errors.message}
                </div>
              ) : null}
            </div>

            <button className="Bookcaregiver_button" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      {load && <Loader />}
    </>
  );
};
export default HelpCenter;
