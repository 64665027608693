import { Link, useLocation } from "react-router-dom";
import Header from "../../Layout/Header";
import Profilemenu from "./Profilemenu";
import "./MyBooking.css";
import { useEffect, useState } from "react";
import {
  caregiverupcomingbooking,
  endjob,
  startjob,
  stripeurl,
} from "../../service/Mainservice";
import Loader from "../../Loader/Loader";
import Pagination from "../../Pagination/Pagination";
import { Alert, Snackbar } from "@mui/material";
import KYCPopup from "./KYC";

// import { messaging } from "../../Firebase";
// import { getToken } from "firebase/messaging";

const CaregiverUpcomingbook = () => {
  const [list, setlist] = useState();
  const [devicetoken, setdevicetoken] = useState();
  const [load, setload] = useState(true);
  const [error, seterror] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [success, setsuccess] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState();
  const [kyc, setkyc] = useState();
  const [url, seturl] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    // const requestNotificationPermission = async () => {
    //   try {
    //     const permissionResult = await Notification.requestPermission();
    //     console.log(permissionResult);
    //     if (permissionResult === "granted") {
    //       const currentToken = await getToken(messaging, {
    //         vapidKey:
    //           "BOS2jENcfmydQnDnOwt6HvXqiYV4u8XQ0ay2_eYlLdj8B7THtDnKgYKKnvXR-LXRsEVsYqBOsvuLTnh10AE0rRc",
    //       });
    //       console.log(currentToken);
    //       setdevicetoken(currentToken);
    //       // Use the token as needed
    //     }
    //   } catch (error) {
    //     console.error("Error obtaining device token:", error);
    //   }
    // };

    // requestNotificationPermission();
    getupcoming(1);
    checkstripe();
  }, []);

  const checkstripe = async () => {
    const response = await stripeurl();
    console.log(response);
    if (response.data.url) {
      seturl(response.data.url);
      setkyc(true);
    }
  };

  const getupcoming = async (val) => {
    try {
      const response = await caregiverupcomingbooking(val);
      setlist(response.data.data);
      setcurrentPage(response.data.pagination.current_pages);
      settotalPages(response.data.pagination.total_pages);

      setload(false);
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };

  const handlestartjob = async (id) => {
    setload(true);
    try {
      const response = await startjob(id, devicetoken);
      console.log(response);
      getupcoming();
      setload(false);
      setOpen2(true);
      setsuccess(response.data.message);
    } catch (error) {
      console.log(error);
      seterror(error.message);
      setOpen(true);
      setload(false);
    }
  };
  const handleendjob = async (id) => {
    setload(true);

    try {
      const response = await endjob(id, devicetoken);
      getupcoming();
      setload(false);
      setOpen2(true);
      setsuccess(response.data.message);
    } catch (error) {
      seterror(error.message);
      setload(false);
      setOpen(true);
      setload(false);
    }
  };

  return (
    <>
      <Header title="My Booking" headerbg="upcomingbg" />
      <div className="editprofile_data">
        <Profilemenu />
        <div className="editprofile_info">
          <h2 className="editprofile_maintitle">My Booking</h2>
          <div className="booking_status">
            <Link
              to="/CompanionUpcomingbook"
              className={
                path === "/CompanionUpcomingbook"
                  ? "booking_status_title selected_title"
                  : "booking_status_title"
              }
            >
              Upcoming
            </Link>
            <Link
              to="/CompanionCompletedbook"
              className={
                path === "/CompanionCompletedbook"
                  ? "booking_status_title selected_title"
                  : "booking_status_title"
              }
            >
              Completed
            </Link>
          </div>
          {list ? (
            <div>
              <div className="mybooking_cardlist">
                {list.map((data) => (
                  <div className="mybooking_card">
                    <div className="mybooking_cardup">
                      <div className="mybooking_cardinfo1">
                        <p>Request ID</p>
                        <h3>{data.request_id}</h3>
                      </div>
                      <div className="mybooking_cardinfo1">
                        <p>Booking Date</p>
                        <h3>{data.booking_date}</h3>
                      </div>
                      <div className="mybooking_cardinfo1">
                        <p>Request Date</p>
                        <h3>{data.request_date}</h3>
                      </div>
                    </div>

                    <div className="mybooking_cardup">
                      <div className="mybooking_cardinfo1">
                        <p>Time</p>
                        <h3>{data.booking_time}</h3>
                      </div>
                      <div className="mybooking_cardinfo1">
                        <p>Address</p>
                        <h3>{data.address}</h3>
                      </div>
                    </div>
                    <div className="mybooking_cardup">
                      <div className="mybooking_cardinfo">
                        <p>Client</p>
                        <h3 className="mybooking_caretakerdata">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}${data.client_pic}`}
                          />
                          {data.client_name}
                        </h3>
                      </div>
                    </div>

                    {data.booking_status === "2" ? (
                      <>
                        <p
                          className="mybooking_help"
                          onClick={() => handlestartjob(data.id)}
                        >
                          Start Job
                        </p>
                      </>
                    ) : data.booking_status === "3" ? (
                      <>
                        <p
                          className="mybooking_help"
                          onClick={() => handleendjob(data.id)}
                        >
                          End Job
                        </p>
                      </>
                    ) : (
                      <p className="mybooking_help">Booking Completed</p>
                    )}
                  </div>
                ))}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                getorderlist={getupcoming}
              />
            </div>
          ) : (
            <div className="blank_booking">No Upcoming Bookings</div>
          )}
        </div>
      </div>
      {load && <Loader />}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={open2}
        autoHideDuration={3000}
        onClose={() => setOpen2(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      <KYCPopup isOpen={kyc} setisOpen={setkyc} url={url} />
    </>
  );
};
export default CaregiverUpcomingbook;
