import { useNavigate } from "react-router-dom";
import "./Booktype.css";
import Header from "../../Layout/Header";

const Booktype = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header title="Choose Booking Type" headerbg="caregiverbg2" />
      <div className="Bookcaregiverdiv">
        <div className="Book_type_main">
          <h3 className="Booktype_heading">
            What Type of Booking are you looking for?
          </h3>
          <div
            className="Booking_types"
            onClick={() =>
              navigate("/Bookcaregiver_Fixeddate", {
                state: {
                  type: "OneTime",
                },
              })
            }
          >
            <p>Consecutive Date Booking</p>
            <p className="Booking_describe">
              Book a companion for consecutive date range. Choose the start and
              end dates from the calendar.{" "}
            </p>
          </div>
          <div
            className="Booking_types"
            onClick={() =>
              navigate("/Bookcaregiver_Multipledate", {
                state: {
                  type: "Flexible",
                },
              })
            }
          >
            <p>Selective Date Booking</p>
            <p className="Booking_describe">
              Book a companion for multiple dates. Select the dates you need a
              companion for.{" "}
            </p>
          </div>
          <div
            className="Booking_types"
            onClick={() =>
              navigate("/Bookcaregiver_Recurring", {
                state: {
                  type: "Recurring",
                },
              })
            }
          >
            <p>Recurring Booking</p>
            <p className="Booking_describe">
              Book a companion for a recurring schedule. Choose the start date
              and select the days you need a companion for. This booking will
              continue until you cancel it.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Booktype;
